import HttpService, { BaseResponse } from "../base";
// Types
import type { CollectorDocuments } from "src/types/collectors";
import type { BaseApiResponse } from "src/types/http";

class CollectorsProvider extends HttpService {
	constructor() {
		super({ group: "data" });
	}

	/**
	 * get table collector
	 *
	 * @async
	 * @version 1.0.2
	 */
	public getTableCollectors(): BaseResponse<BaseApiResponse<CollectorDocuments>> {
		return this.httpService.get("table-collector");
	}

	/**
	 * get published extract table collector
	 *
	 * @async
	 * @version 1.0.2
	 */
	public getExtractedTableCollector(id: string): BaseResponse<BaseApiResponse<CollectorDocuments>> {
		return this.httpService.get(`table-collector/${id}`);
	}
}

const CollectorsService = new CollectorsProvider();

export default CollectorsService;
