// Utilities
import * as R from "ramda";
// Helpers
import { parseQuery } from "src/helpers/url";
// Types
import type { Dictionary } from "src/types/common";
import type { BandsPreviewDataVersion, Filters } from "src/types/dynamic";
import type { Document } from "src/types/documents";

/**
 * get title of version
 *
 * @param {string} str
 * @return {string}
 */
export function getVersionHeader(str: string): string {
	const normalizeString = str.toLocaleLowerCase() !== "interworkingversion" ? R.toUpper : R.always("Interworking");
	if (["lteversion", "fr1version", "fr2version", "interworkingversion"].includes(str.toLocaleLowerCase())) {
		return R.pipe(R.replace("Version", ""), normalizeString)(str);
	}
	return normalizeString(str);
}

/**
 * return array of selected data versions that has NO_ in their values
 *
 * @param {[string, string][]} dataVersionPairs
 * @returns {string[]}
 */
function getSelectedVersionsHasNone(dataVersionPairs: [string, string][]): string[] {
	return R.pipe(
		R.filter<[string, string], any>((item) => R.test(/NO_/i, item[1])),
		R.map<[string, string], string>((item) => item[0]),
		R.map<string, string>((item) => R.replace("Versions", "Version", item)),
	)(dataVersionPairs);
}

/**
 * return array of columns to be filtered out
 *
 * @param {string[]} versionsContainNone
 * @param {Filters} filtersColumns
 * @returns {string[]}
 */
function getFilterColumnsContainNone(versionsContainNone: string[], filtersColumns: Filters): string[] {
	const filterColumnsPairs: [string, string[]][] = R.toPairs(filtersColumns);

	/* did this because we wanted to show the filtered out columns in case they exist
	in dataversion not contain none - for e.g. b column exists both in fr1 and fr2 */
	const columnsNotNone = R.pipe<[string, string[]][], [string, string[]][], string[][], string[]>(
		R.filter((item) => !R.includes(item[0], versionsContainNone)),
		R.map((item) => item[1]),
		R.flatten,
	)(filterColumnsPairs);

	return R.pipe<[string, string[]][], [string, string[]][], Dictionary, string[], string[], string[], string[]>(
		R.filter<[string, string[]], any>((item) => R.includes(item[0], versionsContainNone)),
		R.fromPairs,
		R.values,
		R.flatten,
		R.uniq,
		R.filter((item) => !R.includes(item, columnsNotNone)),
	)(filterColumnsPairs);
}

/**
 * get columns of data versions that contains None
 *
 * @param {Dictionary<string>} currentDataVersions
 * @param {Filters} dataVersionsDedicatedColumns
 * @return {string[]}
 */
export function getFilteredOutDataVersionColumns(
	currentDataVersions: Dictionary<string>,
	dataVersionsDedicatedColumns: Filters,
): string[] {
	const dataVersionPairs: [string, string][] = R.toPairs<string>(currentDataVersions);
	const versionsContainNone = getSelectedVersionsHasNone(dataVersionPairs);
	return getFilterColumnsContainNone(versionsContainNone, dataVersionsDedicatedColumns);
}

/**
 * returns  mapping of ts number to the doctype to show inside dropdown instead of ts numbers
 *
 * @param {Document[]} documents - list of published documents
 * @return {Dictionary<string>}
 */
export function getTsNumberToDocumentTypeMapping(documents: Document[]): Dictionary<string> {
	return R.pipe(
		R.map<Document, Dictionary<string>>((item) => ({
			[item.tsNumber]:
				item.documentType === "inter"
					? `interworking (${item.tsNumber})`
					: `${item.documentType} (${item.tsNumber})`,
		})),
		R.uniq,
		R.map((item) => R.flatten(R.toPairs(item))),
		R.reduce<string[], Dictionary<string>>(
			(acc, item) => ({
				...acc,
				[item[0]]: item[1],
			}),
			{},
		),
	)(documents);
}

/**
 * get document id from dataVersion
 *
 * @param {Document[]} documents - list of published documents
 * @param {string} tsNumber - selected tsNumber
 * @param {string} version - selected version
 * @return {string}
 */
export function getDocument(
	documents: Document[],
	group: string,
	dataType: string,
	version: string,
): Document | undefined {
	return R.find(
		R.where({
			group: R.equals(group),
			dataType: R.equals(dataType),
			version: R.equals(version),
		}),
	)(documents);
}

/**
 * remove the Version from versionName
 *  for example convert fr1Version to fr1
 *
 * @param {string} version
 * @return {string}
 */
export function getSimpleVersionType(version: string): string {
	return version?.split("Version")?.[0];
}

/**
 * Extract Bands Preview configuration from the URL Query params
 *
 * @returns {Record<keyof BandsPreviewDataVersion, string>} Bands Data version params
 */
export function dynamicQueries(): Record<keyof BandsPreviewDataVersion, string> {
	const { queries } = parseQuery();

	return queries;
}
