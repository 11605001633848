/**
 * @module Constants/User
 * @desc All user constant variables
 */

import { createReduxConstant } from "src/helpers";

const prefix = createReduxConstant("USER");

/**
 * @module USER
 * @desc get and set user info
 */
export const SET_USER_INFO = prefix("SET_USER_INFO");
export const SAGAS_SET_USER_INFO = prefix("SAGA_SET_USER_INFO");

/**
 * @module LOGIN
 * @desc login and send access code to email
 */
export const SAGAS_LOGIN = prefix("SAGA_LOGIN");

/**
 * @module LOGOUT
 * @desc logout the user
 */
export const SAGAS_LOGOUT = prefix("SAGA_LOGOUT");

/**
 * @module LOGIN
 * @desc set send code state
 */
export const SET_SENT_CODE = prefix("SET_SENT_CODE");

/**
 * @module LOGIN
 * @desc set show access code state
 */
export const SET_SHOW_ACCESS_CODE = prefix("SET_SHOW_ACCESS_CODE");

/**
 * @module LOGIN
 * @desc set login information such as companyId and email
 */
export const SET_LOGIN_INFO = prefix("SET_LOGIN_INFO");

/**
 * @module ACCESS_CODE
 * @desc access code submit
 */
export const SAGAS_SUBMIT_ACCESS_CODE = prefix("SAGA_SUBMIT_ACCESS_CODE");

/**
 * @module GET_REFRESH_TOKEN
 * @desc get refresh token
 */
export const SAGAS_GET_REFRESH_TOKEN = prefix("SAGA_GET_REFRESH_TOKEN");

/**
 * @module ACCESS_CODE
 * @desc set access token data
 */
export const SET_ACCESS_TOKEN_DATA = prefix("SET_ACCESS_TOKEN_DATA");

/**
 * @module LOADING
 * @desc set loading
 */
export const SET_LOADING = prefix("SET_LOADING");

/**
 * @module SIGN_UP
 * @desc set show sign up state
 */
export const SET_SHOW_SIGN_UP = prefix("SET_SHOW_SIGN_UP");

/**
 * @module SIGN_UP
 * @desc sign up submit
 */
export const SAGAS_SIGN_UP = prefix("SAGA_SIGN_UP");

/**
 * @module SET_USER_PERMISSIONS
 * @desc set user permissions
 */
export const SET_USER_PERMISSIONS = prefix("SET_USER_PERMISSIONS");

/**
 * @module SET_USER_ENTITIES
 * @desc set user entities
 */
export const SET_USER_ENTITIES = prefix("SET_USER_ENTITIES");

/**
 * @module POST_CUSTOMER_FEEDBACK
 * @desc post customer feedback
 */
export const POST_CUSTOMER_FEEDBACK = prefix("POST_CUSTOMER_FEEDBACK");
