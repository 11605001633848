import React from "react";
// Utilities
import cn from "classnames";
// Styles
import styles from "./index.module.scss";

export const Loading: React.FC = () => {
	return (
		<div className={styles["loading-container"]}>
			<div className={styles["loading-container__cell"]}>
				<div className={styles["loading-container__logo"]} />
				<div className={styles["loading-container__progressbar"]}>
					<div className={styles["loading-container__line"]} />
					<div className={cn(styles["loading-container__dot"], styles["loading-container__dot1"])} />
					<div className={cn(styles["loading-container__dot"], styles["loading-container__dot2"])} />
					<div className={cn(styles["loading-container__dot"], styles["loading-container__dot3"])} />
				</div>
			</div>
		</div>
	);
};
