import { UserInfoResponse } from "src/types/users";
import HttpService, { BaseResponse } from "../base";

class UserProvider extends HttpService {
	constructor() {
		super({ group: "users" });
	}

	/**
	 * get user info
	 *
	 * @async
	 * @version 1.0.0
	 */
	public getUserInfo(): BaseResponse<UserInfoResponse> {
		return this.httpService.get("users/self");
	}

	/**
	 * Post customer feedback
	 *
	 * @async
	 * @version 1.0.0
	 */
	public postCustomerFeedback(data: any): BaseResponse<UserInfoResponse> {
		return this.httpService.post("feedback", data);
	}
}

const UserService = new UserProvider();

export default UserService;
