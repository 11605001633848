/**
 * @module Actions/User
 * @desc All User actions
 */
import * as types from "./user.constants";
import type { Action, ExtraActionInfo } from "src/types/common";
import type {
	UserInfo,
	LoginRequestData,
	SignUpFormValues,
	VerifyAccessCodeResponse,
	LoginInfoKeys,
	LoginInfoActionPayload,
} from "src/types/users";

class Actions {
	/**
	 * get and set user info
	 * @param {UserInfo} data
	 * @param {ExtraActionInfo} options
	 * @return {Action<UserInfo | null>}
	 */
	public setUserInfo(data: UserInfo | null, options?: ExtraActionInfo): Action<UserInfo | null> {
		return {
			type: options?.sagas ? types.SAGAS_SET_USER_INFO : types.SET_USER_INFO,
			payload: data,
		};
	}

	/**
	 * login and send access code to email
	 * @param {LoginRequestData} data
	 * @return {Action<LoginRequestData>}
	 */
	public login(data: LoginRequestData): Action<LoginRequestData> {
		return {
			type: types.SAGAS_LOGIN,
			payload: data,
		};
	}

	/**
	 * logout the user
	 * @return {Actions}
	 */
	public logout(navigate?): Action {
		return {
			type: types.SAGAS_LOGOUT,
			navigate,
		};
	}

	/**
	 * set sent code state
	 * @param {boolean} data
	 * @return {Action}
	 */
	public setSentCode(data: boolean): Action<boolean> {
		return {
			type: types.SET_SENT_CODE,
			payload: data,
		};
	}
	/**
	 * set show access code state
	 * @param {boolean} data
	 * @return {Action}
	 */
	public setShowAccessCode(data: boolean): Action<boolean> {
		return {
			type: types.SET_SHOW_ACCESS_CODE,
			payload: data,
		};
	}

	/**
	 * submit access code
	 * @param {string} data
	 * @return {Action<string>}
	 */
	public submitAccessCode(data: string, navigate): Action<string> {
		return {
			type: types.SAGAS_SUBMIT_ACCESS_CODE,
			payload: data,
			navigate,
		};
	}

	/**
	 * get refresh token
	 * @return {Action}
	 */
	public getRefreshToken(): Action {
		return {
			type: types.SAGAS_GET_REFRESH_TOKEN,
		};
	}

	/**
	 * set access token data
	 *
	 * @public
	 * @param {VerifyAccessCodeResponse} data
	 * @return {Action<VerifyAccessCodeResponse>}
	 */
	public setAccessTokenData(data: VerifyAccessCodeResponse): Action<VerifyAccessCodeResponse> {
		return {
			type: types.SET_ACCESS_TOKEN_DATA,
			payload: data,
		};
	}

	/**
	 * set loading
	 *
	 * @public
	 * @param {boolean} data
	 * @return {Action<boolean>}
	 */
	public setLoading(data: boolean): Action<boolean> {
		return {
			type: types.SET_LOADING,
			payload: data,
		};
	}

	/**
	 * set show sign up state
	 *
	 * @public
	 * @param {boolean} data
	 * @return {Action<boolean>}
	 */
	public setShowSignUp(data: boolean): Action<boolean> {
		return {
			type: types.SET_SHOW_SIGN_UP,
			payload: data,
		};
	}

	/**
	 * sign up submit
	 *
	 * @public
	 * @param {SignUpFormValues} data
	 * @return {Action<SignUpFormValues>}
	 */
	public signUp(data: SignUpFormValues): Action<SignUpFormValues> {
		return {
			type: types.SAGAS_SIGN_UP,
			payload: data,
		};
	}

	/**
	 * set login information such as companyId and email
	 *
	 * @public
	 * @param {LoginInfoKeys} key
	 * @param {string} value
	 * @return {Action<LoginInfoActionPayload>}
	 */
	public setLoginInfo(key: LoginInfoKeys, value: string): Action<LoginInfoActionPayload> {
		return {
			type: types.SET_LOGIN_INFO,
			payload: { key, value },
		};
	}

	/**
	 * set user permissions
	 *
	 * @public
	 * @param {string[] | null} permissions
	 * @return {Action<string[] | null>}
	 */
	public setPermissions(permissions: string[] | null): Action<string[] | null> {
		return {
			type: types.SET_USER_PERMISSIONS,
			payload: permissions,
		};
	}

	/**
	 * set user permissions
	 *
	 * @public
	 * @param {string[] | null} permissions
	 * @return {Action<string[] | null>}
	 */
	public setEntities(entities: any): Action<any> {
		return {
			type: types.SET_USER_ENTITIES,
			payload: entities,
		};
	}

	/**
	 * post customer feedback
	 *
	 * @public
	 * @param {string} feedback
	 * @return {Action<string[] | null>}
	 */
	public postCustomerFeedback(data: any, callback: () => void): Action<any> {
		return {
			type: types.POST_CUSTOMER_FEEDBACK,
			payload: data,
			callback,
		};
	}
}

const UserActions = new Actions();

export default UserActions;
