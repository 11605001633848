import HttpService, { BaseResponse } from "../base";
// Types
import type {
	LoginRequestData,
	SignUpData,
	SubmitAccessCodeRequestData,
	VerifyAccessCodeResponse,
} from "src/types/users";

class AuthenticationProvider extends HttpService {
	constructor() {
		super({ group: "auth" });
	}

	/**
	 * login and send email
	 *
	 * @async
	 * @version 1.0.0
	 */
	public login(data: LoginRequestData): BaseResponse {
		return this.httpService.post("login", {
			...data,
		});
	}

	/**
	 * Verify access code - This API should be called when you called the {@link login|login endpoint} before.
	 *
	 * @public
	 * @async
	 * @version 1.0.0
	 * @return BaseResponse<VerifyAccessCodeResponse>
	 */
	public verifyAccessCode(data: SubmitAccessCodeRequestData): BaseResponse<VerifyAccessCodeResponse> {
		return this.httpService.post("login/verify", {
			...data,
		});
	}

	/**
	 * get refresh token.
	 *
	 * @public
	 * @async
	 * @version 1.0.0
	 * @return BaseResponse<VerifyAccessCodeResponse>
	 */
	public getRefreshToken(data: string): BaseResponse<VerifyAccessCodeResponse> {
		return this.httpService.post("auth/refreshtoken", {
			token: data,
		});
	}

	/**
	 * logout
	 *
	 * @public
	 * @async
	 * @version 1.0.2
	 * @return BaseResponse<VerifyAccessCodeResponse>
	 */
	public logout(): BaseResponse {
		return this.httpService.get("auth/logout");
	}

	/**
	 * signUp and send email
	 *
	 * @async
	 * @version 1.0.41
	 */
	public signUp(data: SignUpData): BaseResponse {
		return this.httpService.post("login/signup", {
			...data,
		});
	}
}

const AuthenticationService = new AuthenticationProvider();

export default AuthenticationService;
