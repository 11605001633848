import * as React from "react";

// Styles
import cn from "classnames";
import styles from "./index.module.scss";

type Color = "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "dark" | "light";

export interface Props {
	description: React.ReactNode | string;
	className?: string;
	color?: Color;
}

const EmptyState: React.FC<Props> = (props) => {
	return (
		<div
			className={cn(styles["empty-state"], props.className, {
				[styles[`empty-state--background-${props.color}`]]: props.color,
			})}
		>
			{props.description}
		</div>
	);
};

EmptyState.defaultProps = {
	color: "warning",
};

export default EmptyState;
