import Cookies from "js-cookie";
import { isProduction } from "src/helpers/index";
import { read, store, remove } from "./localStorage";
import { JWTTokenDTO } from "src/types/users";

const domain = import.meta.env.VITE_APP_COOKIE_DOMAIN;
const CookiesOptions = isProduction() ? { domain } : {};

function parseJWTToken(token: string): JWTTokenDTO {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(""),
	);

	return JSON.parse(jsonPayload);
}

export const readToken = (): string | null => {
	const token = read("accessToken") || read("accessToken", window.sessionStorage);

	if (token === "null") {
		return null;
	} else {
		return token;
	}
};
export const removeToken: ReturnType<any> = () => {
	remove("accessToken") || remove("accessToken", window.sessionStorage);

	Cookies.remove("access_token", CookiesOptions);
	Cookies.remove("refresh_token", CookiesOptions);
};

/**
 * set access and refresh tokens
 *
 * @param {string} accessToken
 * @param {string} refreshToken
 */
export function setTokens(accessToken: string, refreshToken: string): void {
	store("refreshToken", refreshToken);
	store("accessToken", accessToken);

	const accessTokenExpires = new Date(parseJWTToken(accessToken).exp * 1000);
	const refreshTokenExpires = new Date(parseJWTToken(refreshToken).exp * 1000);

	Cookies.set("access_token", accessToken, { ...CookiesOptions, expires: accessTokenExpires });
	Cookies.set("refresh_token", refreshToken, { ...CookiesOptions, expires: refreshTokenExpires });
}
