/**
 * @module Actions/Bands
 * @desc All Bands actions
 */
// Types
import type { Action, ExtraActionInfo, KeyValue } from "src/types/common";
import type {
	DynamicTableParams,
	PageStatus,
	RevisionInfo,
	EditedField,
	DynamicResetAll,
	GraphicsParams,
	DataRateParams,
	BandsGraphicsChart,
	BandsGraphics,
	VisualizationResponse,
} from "src/types/dynamic";
// Constants
import * as types from "./dynamic.constants";
import { DYNAMIC_ACTIONS_TYPES } from "./dynamic.types";
import {
	DeleteProfileRequest,
	GetProfileRequest,
	GroupByData,
	PreviewGroupByData,
	SaveProfileRequest,
} from "src/types/dynamic";

class DynamicActionsModel {
	/**
	 * Get and Set the Bands header
	 *
	 * @category Table header
	 * @param {DYNAMIC_ACTIONS_TYPES.HEADER_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.HEADER_RETURN}
	 */
	dynamicHeader(payload: DYNAMIC_ACTIONS_TYPES.HEADER_PAYLOAD): DYNAMIC_ACTIONS_TYPES.HEADER_RETURN {
		return {
			type: payload.meta?.sagas ? types.SAGAS_HEADER : types.HEADER,
			payload,
		};
	}

	/**
	 * Get and Set the Bands origin header
	 *
	 * @category Table origin header
	 * @param {DYNAMIC_ACTIONS_TYPES.HEADER_ORIGIN_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.HEADER_ORIGIN_RETURN}
	 */
	dynamicOriginHeader(
		payload: DYNAMIC_ACTIONS_TYPES.HEADER_ORIGIN_PAYLOAD,
	): DYNAMIC_ACTIONS_TYPES.HEADER_ORIGIN_RETURN {
		return {
			type: types.ORIGIN_HEADER,
			payload,
		};
	}

	/**
	 * Get and Set the Bands data version filters list from header
	 *
	 * @category Table header
	 * @param {DYNAMIC_ACTIONS_TYPES.DATA_VERSION_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.DATA_VERSION_RETURN}
	 */
	dataVersionFilterList(
		payload: DYNAMIC_ACTIONS_TYPES.DATA_VERSION_PAYLOAD,
	): DYNAMIC_ACTIONS_TYPES.DATA_VERSION_RETURN {
		return {
			type: payload.meta?.sagas ? types.SAGAS_DATA_VERSION_FILTER_LIST : types.DATA_VERSION_FILTER_LIST,
			payload,
		};
	}

	/**
	 * Get and Set the Bands body
	 *
	 * @category table body
	 * @param {DYNAMIC_ACTIONS_TYPES.BODY_PAYLOAD} payload - params of ag-grid table
	 * @return {DYNAMIC_ACTIONS_TYPES.BODY_RETURN}
	 */
	body(payload: DYNAMIC_ACTIONS_TYPES.BODY_PAYLOAD): DYNAMIC_ACTIONS_TYPES.BODY_RETURN {
		return {
			type: payload?.meta?.sagas ? types.SAGAS_BODY : types.BODY,
			payload,
		};
	}

	/**
	 * Get and Set the Notes
	 *
	 * @category table notes
	 * @param {DYNAMIC_ACTIONS_TYPES.NOTES_PAYLOAD} payload - params of ag-grid table
	 * @return {DYNAMIC_ACTIONS_TYPES.NOTES_RETURN}
	 */
	notes(payload: DYNAMIC_ACTIONS_TYPES.NOTES_PAYLOAD): DYNAMIC_ACTIONS_TYPES.NOTES_RETURN {
		return {
			type: types.NOTES,
			payload,
		};
	}

	/**
	 * Get and Set the Notes
	 *
	 * @category table notes
	 * @param {DYNAMIC_ACTIONS_TYPES.SELECTED_NOTE_PAYLOAD} payload - params of ag-grid table
	 * @return {DYNAMIC_ACTIONS_TYPES.SELECTED_NOTE_RETURN}
	 */
	setSelectedNote(payload: DYNAMIC_ACTIONS_TYPES.SELECTED_NOTE_PAYLOAD): DYNAMIC_ACTIONS_TYPES.SELECTED_NOTE_RETURN {
		return {
			type: types.SET_NOTE,
			payload,
		};
	}

	/**
	 * Loaded Count of Table
	 *
	 * @category table
	 * @param {number} payload - loaded count
	 * @return {Action<number>}
	 */
	loadedCount(payload: number): Action<number> {
		return {
			type: types.SET_LOADED_COUNT,
			payload,
		};
	}

	/**
	 * Set current table options for persistence
	 *
	 * @category table body
	 * @param {DYNAMIC_ACTIONS_TYPES.CURRENT_TABLE_OPTIONS_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.CURRENT_TABLE_OPTIONS_RETURN}
	 */
	currentTableOptions(
		payload: DYNAMIC_ACTIONS_TYPES.CURRENT_TABLE_OPTIONS_PAYLOAD,
	): DYNAMIC_ACTIONS_TYPES.CURRENT_TABLE_OPTIONS_RETURN {
		return {
			type: payload?.meta?.sagas ? types.SAGAS_CURRENT_TABLE_OPTIONS : types.CURRENT_TABLE_OPTIONS,
			payload,
		};
	}

	/**
	 * Get and Set the Bands row count
	 *
	 * @category Table Body
	 * @param {DYNAMIC_ACTIONS_TYPES.LOADING_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.ROW_COUNT_RETURN}
	 */
	rowCount(payload: DYNAMIC_ACTIONS_TYPES.ROW_COUNT_PAYLOAD): DYNAMIC_ACTIONS_TYPES.ROW_COUNT_RETURN {
		return {
			type: payload?.meta?.sagas ? types.SAGAS_ROW_COUNT : types.ROW_COUNT,
			payload,
		};
	}

	/**
	 * Set the Bands multiple items' loading state
	 *
	 * @category Bands Loading
	 * @param {DYNAMIC_ACTIONS_TYPES.LOADING_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.LOADING_RETURN}
	 */
	loading(payload: DYNAMIC_ACTIONS_TYPES.LOADING_PAYLOAD): DYNAMIC_ACTIONS_TYPES.LOADING_RETURN {
		return {
			type: payload?.meta?.sagas ? types.SAGAS_NEW_LOADING : types.NEW_LOADING,
			payload,
		};
	}

	/**
	 * set selected data version
	 * @param {KeyValue<string | string[], string>[]} payload
	 * @return {Action<KeyValue<string | string[], string>[]>}
	 */
	selectedDataVersion(payload: KeyValue<string | string[], string>[]): Action<KeyValue<string | string[], string>[]> {
		return {
			type: types.SELECTED_DATA_VERSION,
			payload,
		};
	}

	/**
	 * set selected data version
	 * @param {KeyValue<string | string[], string>[]} payload
	 * @return {Action<KeyValue<string | string[], string>[]>}
	 */
	selectedVisualizationCompareDataVersion(
		payload: KeyValue<string | string[], string>[],
	): Action<KeyValue<string | string[], string>[]> {
		return {
			type: types.SELECTED_VISUALIZATION_COMPARE_DATA_VERSION,
			payload,
		};
	}

	/**
	 * set selected data version for visalization
	 * @param {KeyValue<string | string[], string>[]} payload
	 * @return {Action<KeyValue<string | string[], string>[]>}
	 */
	selectedVisualizationDataVersion(
		payload: KeyValue<string | string[], string>[],
	): Action<KeyValue<string | string[], string>[]> {
		return {
			type: types.SELECTED_VISUALIZATION_DATA_VERSION,
			payload,
		};
	}

	/**
	 * set data version modal open state
	 * @param {boolean} data
	 * @return {Action<boolean>}
	 */
	public setDataVersionModalOpened(payload: boolean): Action<boolean> {
		return {
			type: types.SET_DATAVERSION_MODAL_OPEN,
			payload,
		};
	}

	/** Set the Bands params
	 *
	 * @param {DynamicTableParams} payload
	 * @return {Action<DynamicTableParams>}
	 */
	setParams(payload: DynamicTableParams): Action<DynamicTableParams> {
		return {
			type: types.SET_TABLE_PARAMS,
			payload,
		};
	}

	/**
	 * set band tables page
	 * @param {number} payload
	 * @return {Action<number>}
	 */
	setPage(payload: number): Action<number> {
		return {
			type: types.SET_PAGE,
			payload,
		};
	}

	/**
	 * Get bands chart
	 *
	 * @return {Action}
	 */
	getBandsChart(): Action {
		return {
			type: types.SAGAS_CHART,
		};
	}

	/**
	 * set bands chart
	 * @param {BaseBandsActionsParameters<ChartType[]>} payload
	 * @return {Action<BaseBandsActionsParameters<ChartType[]>>}
	 */
	setBandsChart(payload: BandsGraphicsChart): Action<BandsGraphicsChart> {
		return {
			type: types.SET_CHART,
			payload,
		};
	}

	/**
	 * Reset search
	 * @return {Action}
	 */
	resetSearch(): Action {
		return {
			type: types.SAGAS_RESET_SEARCH,
		};
	}

	/**
	 * Reset all filtered or searched actions in the Table
	 * @return {Action}
	 */
	resetAll(payload: DynamicResetAll): Action<DynamicResetAll> {
		return {
			type: types.SAGAS_RESET_ALL,
			payload,
		};
	}

	/**
	 * Create an Export of the Table
	 *
	 * @return {Action<BaseBandsActionsParameters>}
	 */
	export(payload: DYNAMIC_ACTIONS_TYPES.EXPORT_TABLE_RETURN): Action {
		return {
			type: types.SAGAS_EXPORT,
			payload,
		};
	}

	/**
	 * to handle whether get new data or not
	 *
	 * @param {Partial<PageStatus>} payload
	 * @return {Action<Partial<PageStatus>>}
	 */
	createSnapshot(payload: Partial<PageStatus>): Action<Partial<PageStatus>> {
		return {
			type: types.MEMOIZE_SNAPSHOT,
			payload,
		};
	}

	clearedSearchStatus(payload: boolean): Action<boolean> {
		return {
			type: types.CLEARED_SEARCH,
			payload,
		};
	}

	/**
	 * set chartVisible state
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setChartVisible(payload: boolean): Action<boolean> {
		return {
			type: types.SET_IS_CHART_VISIBLE,
			payload,
		};
	}

	/**
	 * set chartVisible state
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setBandsChartVisible(payload: boolean): Action<boolean> {
		return {
			type: types.SET_IS_BANDS_CHART_VISIBLE,
			payload,
		};
	}

	// --- revision ---
	/**
	 * set isRevision state
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setIsRevision(payload: boolean): Action<boolean> {
		return {
			type: types.SET_IS_REVISION,
			payload,
		};
	}

	/**
	 * set revision information
	 *
	 * @param {RevisionInfo} payload
	 * @return {Action<RevisionInfo>}
	 */
	setRevisionInfo(payload: RevisionInfo): Action<RevisionInfo> {
		return {
			type: types.SET_REVISION_INFO,
			payload,
		};
	}

	/**
	 * set row count revision
	 *
	 * @param {RevisionRowCount} payload
	 * @return {Action<RevisionRowCount>}
	 */
	setRevisionRowCount(
		payload: DYNAMIC_ACTIONS_TYPES.REVISION_ROW_COUNT_PAYLOAD,
	): DYNAMIC_ACTIONS_TYPES.REVISION_ROW_COUNT_RETURN {
		return {
			type: types.SET_REVISION_ROW_COUNT,
			payload,
		};
	}

	/**
	 * toggle edit mode to show edit interface notification
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setEditMode(payload: boolean): Action<boolean> {
		return {
			type: types.SET_EDIT_MODE,
			payload,
		};
	}

	/**
	 * toggle edit mode of cells
	 *
	 * @param {DYNAMIC_ACTIONS_TYPES.ENABLE_EDIT_MODE} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.ENABLE_EDIT_MODE_RETURN}
	 */
	setCellsEditable(payload: DYNAMIC_ACTIONS_TYPES.ENABLE_EDIT_MODE): DYNAMIC_ACTIONS_TYPES.ENABLE_EDIT_MODE_RETURN {
		return {
			type: payload.meta.sagas ? types.SAGAS_SET_CELLS_EDITABLE : types.SET_CELLS_EDITABLE,
			payload,
		};
	}

	/**
	 * set edited fields values
	 *
	 * @param {EditedField[]} payload
	 * @return {Action<EditedField[]>}
	 */
	setEditedFieldsValues(payload: EditedField[]): Action<EditedField[]> {
		return {
			type: types.SET_EDITED_FIELDS,
			payload,
		};
	}

	/**
	 * submit edited cells
	 * @param {Record<string, any>[]} addedRows
	 *
	 * @return {Action<Record<string, any>[]>}
	 */
	submitEditedCells(addedRows?: Record<string, any>[]): Action<Record<string, any>[]> {
		return {
			type: types.SAGAS_SUBMIT_EDIT,
			payload: addedRows,
		};
	}

	/**
	 * activate add mode
	 * @param {boolean} isAdd
	 * @return {Action<boolean>}
	 */
	activateAdd(isAdd: boolean): Action<boolean> {
		return {
			type: types.SET_IS_ADD,
			payload: isAdd,
		};
	}

	/**
	 * set add status
	 * @param {"success" | "error" | null} addStatus
	 * @return {Action<"success" | "error" | null>}
	 */
	setAddStatus(addStatus: "success" | "error" | null): Action<"success" | "error" | null> {
		return {
			type: types.SET_ADD_STATUS,
			payload: addStatus,
		};
	}
	/**
	 * set table clickable
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setTableClickable(payload: boolean): Action<boolean> {
		return {
			type: types.MAKE_TABLE_CLICKABLE,
			payload,
		};
	}

	/**
	 * Get and Set the band combo graphics table
	 *
	 * @param {DYNAMIC_ACTIONS_TYPES.GRAPHICS_TABLE_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.GRAPHICS_TABLE_RETURN}
	 */
	handleGraphicsTable(
		payload: DYNAMIC_ACTIONS_TYPES.GRAPHICS_TABLE_PAYLOAD,
	): DYNAMIC_ACTIONS_TYPES.GRAPHICS_TABLE_RETURN {
		return {
			type: payload.meta?.sagas ? types.SAGAS_HANDLE_GRAPHICS_TABLE : types.HANDLE_GRAPHICS_TABLE,
			payload,
		};
	}

	/**
	 * Get and Set the band combo graphics table
	 *
	 * @param {DYNAMIC_ACTIONS_TYPES.GRAPHICS_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.GRAPHICS_RETURN}
	 */
	handleGraphics(payload: DYNAMIC_ACTIONS_TYPES.GRAPHICS_PAYLOAD): DYNAMIC_ACTIONS_TYPES.GRAPHICS_RETURN {
		return {
			type: payload.meta?.sagas ? types.SAGAS_HANDLE_GRAPHICS : types.HANDLE_GRAPHICS,
			payload,
		};
	}

	/**
	 * post and set band combo graphics custom calcuation
	 *
	 * @param {DYNAMIC_ACTIONS_TYPES.GRAPHICS_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.GRAPHICS_RETURN}
	 */
	handleGraphicsPost(
		payload: DYNAMIC_ACTIONS_TYPES.CUSTOM_GRAPHICS_PAYLOAD,
	): DYNAMIC_ACTIONS_TYPES.CUSTOM_GRAPHICS_RETURN {
		return {
			type: types.SAGAS_HANDLE_CUSTOM_GRAPHICS,
			payload,
		};
	}

	/**
	 * set graphics params - uplink and downlink
	 *
	 * @param {GraphicsParams} payload
	 * @return {Action<GraphicsParams>}
	 */
	setGraphicsParams(payload: GraphicsParams): Action<GraphicsParams> {
		return {
			type: types.SET_GRAPHICS_PARAMS,
			payload,
		};
	}

	/**
	 * Get and Set the band combo dataRate table
	 *
	 * @param {DYNAMIC_ACTIONS_TYPES.DATARATE_TABLE_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.DATARATE_TABLE_RETURN}
	 */
	handleDataRateTable(
		payload: DYNAMIC_ACTIONS_TYPES.DATARATE_TABLE_PAYLOAD,
	): DYNAMIC_ACTIONS_TYPES.DATARATE_TABLE_RETURN {
		return {
			type: payload.meta?.sagas ? types.SAGAS_HANDLE_DATARATE_TABLE : types.HANDLE_DATARATE_TABLE,
			payload,
		};
	}
	/**
	 * set dataRateVisible state
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setDataRateVisible(payload: boolean): Action<boolean> {
		return {
			type: types.SET_IS_DATARATE_VISIBLE,
			payload,
		};
	}

	/**
	 * set dataRate params - uplink and downlink
	 *
	 * @param {DataRateParams} payload
	 * @return {Action<DataRateParams>}
	 */
	setDataRateParams(payload: DataRateParams): Action<DataRateParams> {
		return {
			type: types.SET_DATARATE_PARAMS,
			payload,
		};
	}
	/**
	 * Reset factory the whole reducers
	 *
	 * @return {Action}
	 */
	public resetFactory(options?: ExtraActionInfo): Action {
		return {
			type: types.RESET_FACTORY,
		};
	}

	// --- graphic ---

	/**
	 * Get and Set the band graphics table
	 *
	 * @param {DYNAMIC_ACTIONS_TYPES.BANDS_GRAPHICS_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.BANDS_GRAPHICS_RETURN}
	 */
	handleBandsGraphicsTable(
		payload: DYNAMIC_ACTIONS_TYPES.BANDS_GRAPHICS_PAYLOAD,
	): DYNAMIC_ACTIONS_TYPES.BANDS_GRAPHICS_RETURN {
		return {
			type: payload.meta?.sagas ? types.SAGAS_HANDLE_BANDS_GRAPHICS : types.HANDLE_BANDS_GRAPHICS,
			payload,
		};
	}

	/**
	 * set graphics params
	 *
	 * @param {BandsGraphics} payload
	 * @return {Action<BandsGraphics>}
	 */
	setBandsGraphicsParams(payload: BandsGraphics): Action<BandsGraphics> {
		return {
			type: types.SET_BANDS_GRAPHICS_PARAMS,
			payload,
		};
	}

	/**
	 * set visualisation state
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setVisualisationDrawerVisible(payload: boolean): Action<boolean> {
		return {
			type: types.SET_IS_VISUALIZATION_VISIBLE,
			payload,
		};
	}

	// --- visualization ---

	/**
	 * visualization
	 *
	 * @return {Action<BaseBandsActionsParameters>}
	 */
	visualization(payload: DYNAMIC_ACTIONS_TYPES.DYNAMIC_VISUALIZATION): Action {
		return {
			type: types.SAGAS_VISUALIZATION,
			payload,
		};
	}

	/**
	 * set visualization response
	 * @param {VisualizationResponse[]} payload
	 * @return {Action<VisualizationResponse[]>}
	 */
	setVisualization(payload: VisualizationResponse): Action<VisualizationResponse> {
		return {
			type: types.SET_VISUALIZATION,
			payload,
		};
	}

	// --- profile ---

	/**
	 * save a profile
	 * @param {SaveProfileRequest} data
	 * @return {Action<SaveProfileRequest>}
	 */
	public saveProfile(data: SaveProfileRequest): Action<SaveProfileRequest> {
		return {
			type: types.SAGAS_SAVE_PROFILE,
			payload: data,
		};
	}

	/**
	 * save a profile
	 * @param {GetProfileRequest} data
	 * @return {Action<GetProfileRequest>}
	 */
	public getProfile(data: GetProfileRequest): Action<GetProfileRequest> {
		return {
			type: types.SAGAS_GET_PROFILE,
			payload: data,
		};
	}

	/**
	 * delete a profile
	 * @param {DeleteProfileRequest} data
	 * @return {Action<DeleteProfileRequest>}
	 */
	public deleteProfile(payload: any): Action<DeleteProfileRequest> {
		return {
			type: types.SAGAS_DELETE_PROFILE,
			payload,
		};
	}

	/**
	 * Get and Set the Profiles
	 *
	 * @param {DYNAMIC_ACTIONS_TYPES.PROFILES_PAYLOAD} payload
	 * @return {DYNAMIC_ACTIONS_TYPES.PROFILES_RETURN}
	 */
	setProfilesList(payload: DYNAMIC_ACTIONS_TYPES.PROFILES_PAYLOAD): DYNAMIC_ACTIONS_TYPES.PROFILES_RETURN {
		return {
			type: types.SET_PROFILES,
			payload,
		};
	}

	/**
	 * set selected entity
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setSelectedEntity(payload: string): Action<string> {
		return {
			type: types.SET_ENTITY,
			payload,
		};
	}

	// --- group by ---
	/**
	 * set isGroupBy state
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setIsGroupBY(payload: boolean): Action<boolean> {
		return {
			type: types.SET_IS_GROUP_BY,
			payload,
		};
	}

	/**
	 * set isGroupBy headers set state
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setIsGroupBYHeadersSet(payload: boolean): Action<boolean> {
		return {
			type: types.SET_IS_GROUP_BY_HEADERS_SET,
			payload,
		};
	}

	/**
	 * set isExitGroupBY state
	 *
	 * @param {boolean} payload
	 * @return {Action<boolean>}
	 */
	setIsExitGroupBY(payload: boolean): Action<boolean> {
		return {
			type: types.SET_IS_EXIT_GROUP_BY,
			payload,
		};
	}

	/**
	 * set group by data
	 *
	 * @param {GroupByData} payload
	 * @return {Action<GroupByData>}
	 */
	setGroupByData(payload: GroupByData): Action<GroupByData> {
		return {
			type: types.SET_GROUP_BY_DATA,
			payload,
		};
	}

	/**
	 * set preview group by data
	 *
	 * @param {GroupByData} payload
	 * @return {Action<GroupByData>}
	 */
	setPreviewGroupByData(payload: PreviewGroupByData): Action<PreviewGroupByData> {
		return {
			type: types.SET_PREVIEW_GROUP_BY_DATA,
			payload,
		};
	}

	/**
	 * Get and Set the group by
	 *
	 * @category group by
	 * @param {boolean} payload - params of ag-grid table
	 * @return {Action<boolean>}
	 */
	setGroupByVisibility(payload: boolean): any {
		return {
			type: types.SET_GROUP_BY_VISIBILITY,
			payload,
		};
	}
}

const DynamicActions = new DynamicActionsModel();

export default DynamicActions;
