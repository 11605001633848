/**
 * @module Sagas/Common
 * @desc All Common sagas
 */

// Utilities
import { all } from "redux-saga/effects";

export default function* networkListeners() {
	// @ts-ignore
	yield all([]);
}
