/**
 * @module Reducer/Documents
 * @desc All Documents reducers
 */
import * as React from "react";
// Utilities
import * as R from "ramda";
// Types
import type { Action, Dictionary } from "src/types/common";
import type {
	Document,
	DocumentCurrentOptions,
	DocumentsLoading,
	GroupWithDedicatedDataTypes,
} from "src/types/documents";
import type { PDFTreeNode } from "pdfjs-dist/webpack";
// Constants
import * as types from "./documents.constants";

export interface DocumentsState {
	/** document page loading */
	loading: DocumentsLoading;
	/** whole published documents that came from api */
	publishedDocuments: Document[];
	/** list of ts numbers and their dedicated versions */
	dataVersionDataList: GroupWithDedicatedDataTypes[];
	/** selected ts number item */
	selectedGroup: string;
	/** selected ts number item */
	selectedDataType: string;
	/** selected version item */
	selectedVersion: string;
	/** list of ts numbers to be shown in dropdown */
	tsNumberList: string[];
	/** list of versions to be shown in dropdown */
	versionList: string[];
	/** open state of dataversion modal */
	dataVersionModalOpened: boolean;
	/** mapping of ts number to the doctype to show inside dropdown instead of ts numbers */
	tsNumberToDocumentTypeMapping: Partial<Dictionary<string>>;
	/** document id - to requst from api */
	selectedDocument: Partial<Document>;
	/** document page current options - used for persistancy - consists of dataVersion,scale,pageNumber,rotate,search,scrollMode, cursorMode */
	documentCurrentOptions: DocumentCurrentOptions;
	/** outline of pdf - used for table of contents */
	outline: PDFTreeNode[] | null;
	/** open state of toc */
	tocIsOpened: boolean;
	/** lock state of toc */
	tocIsLocked: boolean;
}

const initialState: DocumentsState = {
	loading: {
		dataVersion: false,
		documents: false,
		pdf: true,
		print: false,
	},
	publishedDocuments: [],
	dataVersionDataList: [],
	selectedGroup: "",
	selectedDataType: "",
	selectedVersion: "",
	tsNumberList: [],
	versionList: [],
	dataVersionModalOpened: false,
	tsNumberToDocumentTypeMapping: {},
	selectedDocument: {},
	documentCurrentOptions: {
		dataVersion: {
			tsNumber: "",
			version: "",
		},
		scale: {
			number: 1,
			type: "",
		},
		rotate: 0,
		scrollMode: {
			text: "vertical",
			number: 0,
		},
		cursorMode: {
			text: "textSelection",
			number: 0,
		},
		page: 1,
		search: {
			searchValue: "",
			matchWholeWord: false,
			caseSensitive: false,
		},
	},
	outline: null,
	tocIsOpened: true,
	tocIsLocked: true,
};

const reducer: React.Reducer<DocumentsState, Action> = (state = initialState, action) => {
	const update = R.mergeRight<DocumentsState>(state);
	switch (action.type) {
		case types.SET_LOADING:
			return update({
				loading: {
					...state.loading,
					[action.payload.type]: action.payload.status,
				},
			});
		case types.SET_PUBLISHED_DOCUMENTS:
			return update({
				publishedDocuments: action.payload,
			});
		case types.SET_DATAVERSIONS_LIST:
			return update({
				dataVersionDataList: action.payload,
			});
		case types.SET_SELECTED_GROUP:
			return update({
				selectedGroup: action.payload,
			});
		case types.SET_SELECTED_DATA_TYPE:
			return update({
				selectedDataType: action.payload,
			});
		case types.SET_SELECTED_VERSION:
			return update({
				selectedVersion: action.payload,
			});
		case types.SET_TSNUMBER_LIST:
			return update({
				tsNumberList: action.payload,
			});
		case types.SET_VERSION_LIST:
			return update({
				versionList: action.payload,
			});
		case types.SET_DATAVERSION_MODAL_OPEN:
			return update({
				dataVersionModalOpened: action.payload,
			});
		case types.SET_TSNUMBER_TO_DOCUMENT_TYPE_MAPPING:
			return update({
				tsNumberToDocumentTypeMapping: action.payload,
			});
		case types.SET_SELECTED_DOCUMENT:
			return update({
				selectedDocument: action.payload,
			});
		case types.HANDLE_TOC_OPEN:
			return update({
				tocIsOpened: action.payload,
			});
		case types.HANDLE_TOC_LOCKED:
			return update({
				tocIsLocked: action.payload,
			});
		case types.SET_CURRENT_OPTION:
			return update({
				documentCurrentOptions: {
					...state.documentCurrentOptions,
					[action.payload.key]: action.payload.value,
				},
			});
		case types.RESET_CURRENT_OPTIONS:
			return update({
				documentCurrentOptions: initialState.documentCurrentOptions,
			});
		case types.SET_OUTLINE:
			return update({
				outline: action.payload,
			});

		default:
			return state;
	}
};

export default reducer;
