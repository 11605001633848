/**
 * @module Actions/Notifications
 * @desc All Notifications actions
 */
import { Action, ExtraActionInfo } from "src/types/common";
import { Notifications } from "src/types/notifications";
import * as types from "./notifications.constants";

class Actions {
	/**
	 * get notifications
	 * @param {Notifications[] | null} data
	 * @param {ExtraActionInfo} options
	 * @return {Action<Notifications[] | null>}
	 */
	public setNotifications(data: Notifications[] | null, options?: ExtraActionInfo): Action<Notifications[] | null> {
		return {
			type: options?.sagas ? types.SAGAS_SET_NOTIFICATIONS : types.SET_NOTIFICATIONS,
			payload: data,
		};
	}

	/**
	 * set loading state
	 * @param {boolean} loading
	 * @return {Action<boolean>}
	 */
	public setLoading(loading: boolean): Action<boolean> {
		return {
			type: types.SET_LOADING,
			payload: loading,
		};
	}

	/**
	 * get notifications unread count
	 * @param {number | null} data
	 * @param {ExtraActionInfo} options
	 * @return {Action<number | null>}
	 */
	public setNotificationsUnreadCount(data: number | null, options?: ExtraActionInfo): Action<number | null> {
		return {
			type: options?.sagas ? types.SAGAS_SET_NOTIFICATIONS_UNREAD_COUNT : types.SET_NOTIFICATIONS_UNREAD_COUNT,
			payload: data,
		};
	}
}

const NotificationsActions = new Actions();

export default NotificationsActions;
