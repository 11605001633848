import * as React from "react";
// Helpers
import { read, store } from "src/helpers/localStorage";
// Utilities
import * as DarkReader from "darkreader";

const DarkReaderOptions = {
	brightness: 100,
	// contrast: 90,
	// sepia: 0,
	useFont: false,
};
export function useDarkMode() {
	// Local States
	const [isDarkMode, setDarkMode] = React.useState<boolean>(false);
	// Local Variables
	const theme = read("theme");
	const isDark = theme === "darkMode";

	React.useEffect(() => {
		setDarkMode(isDark);
		DarkReader.setFetchMethod(window.fetch);

		if (isDark) {
			enableDarkMode();
		} else {
			disableDarkMode();
		}

		window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", handlePrefersColorSchemeChanges);

		return () => {
			window
				.matchMedia("(prefers-color-scheme: dark)")
				.removeEventListener("change", handlePrefersColorSchemeChanges);
		};
	}, []);

	function handlePrefersColorSchemeChanges(e: MediaQueryListEvent) {
		if (e.matches && !theme) {
			e.matches ? enableDarkMode() : disableDarkMode();
		}
	}

	function toggle() {
		if (!isDarkMode) {
			enableDarkMode();
		} else {
			disableDarkMode();
		}
	}

	function enableDarkMode() {
		setDarkMode(true);
		DarkReader.enable(DarkReaderOptions);
		store("theme", "darkMode");
		document.body.dataset.theme = "dark";
	}

	function disableDarkMode() {
		setDarkMode(false);
		DarkReader.disable();
		store("theme", "lightMode");
		document.body.dataset.theme = "light";
	}

	return {
		toggle,
		isDarkMode,
		enableDarkMode,
		disableDarkMode,
	};
}
