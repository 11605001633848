import HttpService, { BaseResponse } from "../base";
// Types
import type { Dictionary, ContactUsData } from "src/types/common";
import type { NotificationResponse, NotificationsUnreadCountResponse } from "src/types/notifications";

class NotificationsProvider extends HttpService {
	constructor() {
		super({ group: "notifications" });
	}

	/**
	 * get notifications
	 *
	 * @async
	 * @version 1.0.0
	 */
	public getNotifications(params: Dictionary<number>): BaseResponse<NotificationResponse> {
		return this.httpService.get("notifications/self", {
			params,
		});
	}

	/**
	 * get notifications count
	 *
	 * @async
	 * @version 1.0.0
	 */
	public getNotificationsUnreadCount(): BaseResponse<NotificationsUnreadCountResponse> {
		return this.httpService.get("notifications/unread-count");
	}

	/**
	 * contact-us submit
	 *
	 * @async
	 * @version 1.0.41
	 */
	public contactUs(data: ContactUsData): BaseResponse<NotificationsUnreadCountResponse> {
		return this.httpService.post("contact-us", data);
	}
}

const NotificationsService = new NotificationsProvider();

export default NotificationsService;
