// Utilities
import * as R from "ramda";
// Types
import type { Dictionary } from "src/types/common";

/**
 * get plain array from array of objects
 *
 * @param {Dictionary[]} items an array of objects
 * @param {string} key key to create the plain array based of that
 * @returns {any[]} plain array that are the values of selected key
 */
export function getPlainArrayFromArrayOfObject(items: Dictionary[], key: string): string[] {
	return R.map<Dictionary, string>((item) => R.prop(key)(item) ?? item, items || []);
}

/**
 * modified version of R.tail - created this because if the array.length is 1 the R.tail returns empty array
 *
 * @param {any[]} array an array
 * @returns {any[]} plain array that are the values of selected key
 */
export function tail(array: any[]): any[] {
	if (!array || !array.length) {
		return [];
	} else if (array.length === 1) {
		return array;
	} else {
		return R.tail(array);
	}
}
