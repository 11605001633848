import * as React from "react";
// Common components
import { PrivateRoute } from "src/components/PrivateRoute";
import OfflineChecker from "src/components/OfflineChecker";
import { Loading } from "src/components/Loading";
// React Router
import { Routes, Route, Navigate } from "react-router-dom";
// Libraries
import { lazy } from "vite-preload";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import * as Sentry from "@sentry/react";
// Utilities
import { readToken } from "./helpers/token";
// Hooks
import { useDarkMode } from "src/hooks/darkMode.hook";
// Styles
import "./theme/index.scss";

// Pages
const Dashboard = lazy(() => import("src/pages/Dashboard"));
const Login = lazy(() => import("src/pages/Login"));

const isValidUrl = (url?: string) => {
	try {
		const parsedUrl = new URL(url || ""); // Ensure it's a valid URL
		return parsedUrl.protocol === "https:" || parsedUrl.protocol === "http:";
	} catch (error) {
		return false;
	}
};

const safeBaseUrl = isValidUrl(import.meta.env.VITE_APP_BASE_URL) ? import.meta.env.VITE_APP_BASE_URL : null;
const safeLaunchedDomain = isValidUrl(import.meta.env.VITE_APP_LUNCHED_DOMAIN)
	? import.meta.env.VITE_APP_LUNCHED_DOMAIN
	: null;

const App: React.FC = () => {
	useDarkMode();

	return (
		<React.Fragment>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org",
						"@type": "WebSite",
						url: safeLaunchedDomain,
					})}
				</script>
				{safeBaseUrl && <link rel="preconnect" href={safeBaseUrl} />}
				{safeLaunchedDomain && <link rel="canonical" href={safeLaunchedDomain} />}
			</Helmet>

			<OfflineChecker />
			<Suspense fallback={<Loading />}>
				<Routes>
					<Route path="/" element={readToken() ? <Dashboard /> : <Navigate to="/authentication/login" />} />

					<Route
						path="/dashboard/*"
						element={
							<PrivateRoute>
								<Dashboard />
							</PrivateRoute>
						}
					/>
					<Route path="/authentication/login" element={<Login />} />
				</Routes>
			</Suspense>
		</React.Fragment>
	);
};

export default Sentry.withProfiler(App, { name: "App", includeUpdates: false });
