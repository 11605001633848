import HttpService, { BaseResponse } from "../base";
// Types
import type { PublishedDoucments } from "src/types/documents";
import type { BaseApiResponse } from "src/types/http";

class DocumentsProvider extends HttpService {
	constructor() {
		super({ group: "data" });
	}

	/**
	 * get published doucments
	 *
	 * @async
	 * @version 1.0.2
	 */
	public getPublished(): BaseResponse<BaseApiResponse<PublishedDoucments>> {
		return this.httpService.get("documents/published");
	}
}

const DocumentsService = new DocumentsProvider();

export default DocumentsService;
