// UI frameworks
import { notification as AntNotification } from "antd";

export interface Props {
	configs?: any;
	type: "warning" | "error" | "info" | "success";
	description?: string;
	message: string;
}
export const Notification = (props: Props) => {
	const defaultConfig = {
		...props.configs,
		duration: props.type === "error" ? props.configs?.duration || 0 : props.configs?.duration || 4.5,
	};
	return AntNotification[props.type]({
		description: props.description,
		message: props.message,
		...defaultConfig,
	});
};
