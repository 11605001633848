import i18n from "i18next";
import { read } from "../helpers/localStorage";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// resources
import enLocales from "./en/translation";
import deLocales from "./de/translation";

const resources = {
	de: {
		common: deLocales,
	},
	en: {
		common: enLocales,
	},
};

i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		ns: ["common"],
		defaultNS: "common",
		lng: resources[read("lang")] ? read("lang") : "en",
		fallbackLng: "en", // use fa if detected lng is not available
		react: {
			wait: false,
			bindI18n: "languageChanged loaded",
			bindStore: "added removed",
			nsMode: "default",
		},
		detection: {
			order: ["localStorage"],
			lookupLocalStorage: "lang",
			caches: ["localStorage"],
		},
		interpolation: {
			escapeValue: false,
		},
	})
	.then(() => {
		document.querySelector("html").setAttribute("lang", i18n.language);

		return this;
	});

export default i18n;
