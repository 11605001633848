import { isDev } from "src/helpers";

export const ApplicationConfig = {
	base: {
		PORT: import.meta.env.PORT || 80,
		HOST: import.meta.env.HOST || "localhost",
	},
	theme: {},
	pages: {
		bandsCombination: {
			chart: {
				maxCache: 10,
				minCache: 1,
			},
		},
	},
	persist: {
		enabled: false,
		bands: isDev(),
		bandsCombo: isDev(),
		dynamic: isDev(),
		common: isDev(),
		documents: isDev(),
		notifications: isDev(),
		user: isDev(),
	},
};
