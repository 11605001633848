import Stack from "./stack";

class CacheManagement {
	private readonly stack: Stack;

	constructor(max: number) {
		this.stack = new Stack(max);
	}

	insert(id: string, item: any) {
		this.stack.push(id, item);
	}

	delete(id: string) {
		this.stack.delete(id);
	}

	clear() {
		this.stack.clear();
	}

	search(id: string) {
		return this.stack.search(id);
	}

	isExist(id: string): boolean {
		return this.stack.isExist(id);
	}
}

export default CacheManagement;
