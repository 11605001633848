/**
 * @module Actions/Root
 * @desc All root actions
 */
// Types
import { Action, ExtraActionInfo } from "src/types/store";
// Constants
import * as types from "./index.constants";

class RootActionsModel {
	/**
	 * Reset factory the whole application reducers
	 *
	 * @return {Action}
	 */
	public resetFactory(options?: ExtraActionInfo): Action {
		return {
			type: options?.sagas ? types.SAGAS_RESET_FACTORY : types.RESET_FACTORY,
		};
	}
}

const RootActions = new RootActionsModel();

export default RootActions;
