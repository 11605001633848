/**
 * @module Reducer/Notifications
 * @desc All notifications reducers
 */
import * as React from "react";
import * as types from "./notifications.constants";
// Utilities
import * as R from "ramda";
import { Action } from "src/types/common";
import { Notifications } from "src/types/notifications";

export interface NotificationsState {
	notifications: Notifications[];
	notificationsUnreadCount: number;
	loading: boolean;
}

const initialState: NotificationsState = {
	notifications: [],
	notificationsUnreadCount: 0,
	loading: false,
};

const reducer: React.Reducer<NotificationsState, Action> = (state = initialState, action) => {
	const update = R.mergeRight<NotificationsState>(state);
	switch (action.type) {
		case types.SET_NOTIFICATIONS:
			return update({
				notifications: action.payload,
			});
		case types.SET_LOADING:
			return update({
				loading: action.payload,
			});
		case types.SET_NOTIFICATIONS_UNREAD_COUNT:
			return update({
				notificationsUnreadCount: action.payload,
			});
		default:
			return state;
	}
};

export default reducer;
