// Utilities
import * as R from "ramda";
import { getSimpleVersionType } from "src/helpers/dataVersion";
import { NewBandsType } from "src/types/dynamic";
// Types
import type { KeyValue } from "src/types/common";
import { getAccessibleVersions } from "src/pages/Dashboard/components/BandsDataVersion/index.utils";

/**
 *
 * returns the default data versions
 * @param {[string, string[]][]} data
 * @return {KeyValue[]}
 */
export function getDefaultSelectedDataVersions(
	data: [string, string[]][],
	type: NewBandsType | string,
	permissions?: string[],
): KeyValue[] {
	const accessibleVersions = getAccessibleVersions(permissions, type);

	const mappedPermissions = accessibleVersions.reduce((r, acc) => {
		r[acc.versionType] = r[acc.versionType] || [];
		r[acc.versionType].push(acc.versionNumber);
		return r;
	}, {});

	const mappedData = R.map((item) => {
		const key = item[0].toLowerCase();
		const filteredData = R.filter<string>(
			(filterItem) =>
				!permissions || R.includes(filterItem, mappedPermissions[key] ?? []) || filterItem.startsWith("NO"),
			item[1],
		);
		item[1] = filteredData;
		return item;
	}, data);
	return R.map<[string, string[]], KeyValue>(
		(item) => ({
			key: item[0],
			value: item[1][1] ?? getNoneNormalizedValue(item[0]),
		}),
		mappedData,
	);
}

function getNoneNormalizedValue(str: string) {
	return str ? `NO_${getSimpleVersionType(str)?.toUpperCase()}` : "None";
}
