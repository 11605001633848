/**
 * @module Constants/documents
 * @desc All documents constant variables
 */

import { createReduxConstant } from "src/helpers";

const prefix = createReduxConstant("DOCUMENTS");

/**
 * @module DOCUMENTS
 * @desc get list of published doucments
 */
export const SAGAS_GET_PUBLISHED_DOCUMENTS = prefix("SAGA_GET_PUBLISHED_DOCUMENTS");

/**
 * @module DOCUMENTS
 * @desc set list of published doucments
 */
export const SET_PUBLISHED_DOCUMENTS = prefix("SET_PUBLISHED_DOCUMENTS");

/**
 * @module DOCUMENTS
 * @desc set loading
 */
export const SET_LOADING = prefix("SET_LOADING");

/**
 * @module DOCUMENTS
 * @desc set ts numbers with dedicated version list
 */
export const SET_DATAVERSIONS_LIST = prefix("SET_DATAVERSIONS_LIST");

/**
 * @module DOCUMENTS
 * @desc set selected ts number
 */
export const SET_SELECTED_GROUP = prefix("SET_SELECTED_GROUP");

/**
 * @module DOCUMENTS
 * @desc set selected ts number
 */
export const SET_SELECTED_DATA_TYPE = prefix("SET_SELECTED_DATA_TYPE");

/**
 * @module DOCUMENTS
 * @desc set selected version
 */
export const SET_SELECTED_VERSION = prefix("SET_SELECTED_VERSION");

/**
 * @module DOCUMENTS
 * @desc set ts number list
 */
export const SET_TSNUMBER_LIST = prefix("SET_TSNUMBER_LIST");

/**
 * @module DOCUMENTS
 * @desc set version list
 */
export const SET_VERSION_LIST = prefix("SET_VERSION_LIST");

/**
 * @module DOCUMENTS
 * @desc set data version modal open state
 */
export const SET_DATAVERSION_MODAL_OPEN = prefix("SET_DATAVERSION_MODAL_OPEN");

/**
 * @module DOCUMENTS
 * @desc set ts number to documentType mappings
 */
export const SET_TSNUMBER_TO_DOCUMENT_TYPE_MAPPING = prefix("SET_TSNUMBER_TO_DOCUMENT_TYPE_MAPPING");

/**
 * @module DOCUMENTS
 * @desc set selected document
 */
export const SET_SELECTED_DOCUMENT = prefix("SET_SELECTED_DOCUMENT");

/**
 * @module DOCUMENTS
 * @desc read pdf document
 */
export const SAGAS_READ_DOCUMENT = prefix("SAGA_READ_DOCUMENT");

/**
 * @module DOCUMENTS
 * @desc set document current options - including dataversion, scale, page, search
 */
export const SET_CURRENT_OPTION = prefix("SET_CURRENT_OPTION");

/**
 * @module DOCUMENTS
 * @desc resetting the documentCurrentOptions to it's default
 */
export const RESET_CURRENT_OPTIONS = prefix("RESET_CURRENT_OPTIONS");

/**
 * @module DOCUMENTS
 * @desc setting the outline
 */
export const SET_OUTLINE = prefix("SET_OUTLINE");

/**
 * @module DOCUMENTS
 * @desc reset current pdf functions
 */
export const SAGAS_RESET_CURRENT_PDF_FUNCTIONS = prefix("SAGA_RESET_CURRENT_PDF_FUNCTIONS");

/**
 * @module DOCUMENTS
 * @desc handle toc open
 */
export const HANDLE_TOC_OPEN = prefix("HANDLE_TOC_OPEN");

/**
 * @module DOCUMENTS
 * @desc handle toc locked
 */
export const HANDLE_TOC_LOCKED = prefix("HANDLE_TOC_LOCKED");
