class Stack {
	private readonly max: number;

	constructor(max: number) {
		this.max = max;
	}

	private data = new Map<string, any>();

	private isFull() {
		return this.data.size === this.max;
	}

	isExist(id: string): boolean {
		return this.data.has(id);
	}

	search(id: string) {
		return this.data.get(id);
	}

	clear() {
		this.data.clear();
	}

	delete(id: string) {
		this.data.delete(id);
	}

	push(id: string, element: any) {
		if (!this.isExist(id) && !!id) {
			this.data.set(id, element);
			if (this.isFull()) {
				this.shift();
			}
		}
	}

	private shift() {
		const keys = this.data.keys();
		this.delete(keys[0]);
	}
}

export default Stack;
