// Common components
import { Notification } from "src/components/Notification";
// Utilities
import axios, { AxiosError } from "axios";
import qs from "qs";
import { BaseApiResponse } from "src/types/http";
import { readToken, setTokens } from "src/helpers/token";
import { getBaseURL } from "src/helpers";
import { read, store } from "src/helpers/localStorage";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import fileReader from "src/helpers/fileReader";
import { appStore } from "src/store";
import i18n from "src/locales";
// Actions
import UserActions from "src/store/User/user.actions";
// Types
import type { AxiosInstance } from "axios";

interface Config {
	suffix?: string;
	baseURL?: string;
	version?: string;
	group?: string;
}

export type BaseResponse<T = any> = Promise<T & BaseApiResponse>;

/**
 * A HTTP service which created by Axios instance creator
 *
 * @abstract
 */
abstract class BaseAPI {
	protected httpService: AxiosInstance;
	W;
	protected constructor({ group = "", suffix, baseURL = getBaseURL(), version = "/api/v1" }: Config) {
		// create a new instance of the Axios with custom config.
		const url = [baseURL, group ? `/${group}` : null, version, suffix ? `/${suffix}` : null]
			.filter(Boolean)
			.join("");

		this.httpService = axios.create({
			baseURL: url,
			timeout: 60 * (Number(import.meta.env.VITE_APP_HTTP_TIMEOUT) || 1) * 1000,
			validateStatus(status) {
				return status >= 200 && status < 300;
			},
			paramsSerializer: {
				serialize: (params) => this.serializeParams(params),
			},
		});

		this.requestInterceptors();
		this.responseInterceptors();
	}

	private serializeParams(params: Record<string, any>): string {
		const copy = { ...params };

		for (const key in copy) {
			const value = copy[key];
			if (typeof value === "object") {
				if (Array.isArray(value)) {
					if (value.length > 0 && typeof value[0] === "object") {
						copy[key] = value.map((item) => JSON.stringify(item));
					}
				} else {
					copy[key] = JSON.stringify(value);
				}
			}
		}
		return qs.stringify(copy, { arrayFormat: "brackets" });
	}

	private responseInterceptors() {
		this.httpService.interceptors.response.use(
			(response) => response,
			async (error: AxiosError) => {
				if (error.response?.request?.responseType === "blob") {
					const reader = await fileReader(error.response?.data);

					const parsedReader = JSON.parse(reader as string);
					Notification({ message: parsedReader?.message, type: "error" });
				}
				//@ts-ignore
				if (error.response?.data?.message && error.response?.status != 401) {
					//@ts-ignore
					Notification({ message: error.response?.data?.message, type: "error" });
				} else if (error.response?.status >= 500 || !error.response?.status) {
					Notification({
						message: i18n.t("common.serverError"),
						type: "error",
					});
				}
				return Promise.reject(error);
			},
		);
		const currentAccessToken = read("accessToken");
		if (currentAccessToken) {
			createAuthRefreshInterceptor(this.httpService, this.requestRefreshToken);
		}
	}

	private getToken() {
		const token = readToken();

		if (token) {
			return token;
		}
	}

	private requestRefreshToken() {
		const currentRefreshToken = read("refreshToken");
		const refreshTokenUrl = `${getBaseURL()}/auth/api/v1/auth/refreshtoken`;
		return axios
			.post(refreshTokenUrl, {
				token: currentRefreshToken,
			})
			.then((response) => {
				const payload = response.data.payload;
				// put token to LocalStorage
				setTokens(payload.accessToken, payload.refreshToken);
				store("permissions", payload.tokenPayload?.permissions);
				appStore.dispatch(UserActions.setAccessTokenData(payload));

				const token = payload.accessToken;

				if (token) {
					// Add token to the Authorization header
					axios.defaults.headers.common.Authorization = `Bearer ${payload.accessToken}`;
				}

				return Promise.resolve();
			})
			.catch((error) => {
				// NOTE: added to detect CORS error format and will remove in future
				console.log("function*base -> error", JSON.stringify(error));
				if (error.response.config.url === refreshTokenUrl) {
					const logoutEvent = new Event("logout");
					window.dispatchEvent(logoutEvent);
				}
			});
	}

	private requestInterceptors() {
		this.httpService.interceptors.request.use(
			(config) => {
				const token = this.getToken();

				if (token) {
					// Add token to the Authorization header
					config.headers.Authorization = `Bearer ${token}`;
				}

				return config;
			},
			(error) => Promise.reject(error),
		);
	}
}
export default BaseAPI;
