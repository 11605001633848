import { normalizeEntriesModel } from "src/helpers/index";

export function parseQuery() {
	const searchParams = new URLSearchParams(window.location.search);
	const queries = normalizeEntriesModel([...searchParams.entries()]);

	return {
		searchParams,
		queries,
	};
}
