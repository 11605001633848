/**
 * @module Reducer/Common
 * @desc All Common reducers
 */
import * as React from "react";
import * as types from "./common.constants";
// Utilities
import * as R from "ramda";
import { Action } from "src/types/common";

export interface CommonState {
	tabName: string;
	tabGroupName: string;
	loading: boolean;
	resetContactUs: boolean;
	isIntroOpen: boolean;
}

const initialState: CommonState = {
	tabName: undefined,
	tabGroupName: undefined,
	loading: false,
	resetContactUs: false,
	isIntroOpen: false,
};

const reducer: React.Reducer<CommonState, Action> = (state = initialState, action) => {
	const update = R.mergeRight<CommonState>(state);

	switch (action.type) {
		case types.SET_TAB_NAME:
			return update({
				tabName: action.payload,
			});
		case types.SET_TAB_GROUP_NAME:
			return update({
				tabGroupName: action.payload,
			});
		case types.SET_LOADING:
			return update({
				loading: action.payload,
			});
		case types.SET_OPEN_INTRO:
			return update({
				isIntroOpen: action.payload,
			});

		default:
			return state;
	}
};

export default reducer;
