// Types
import type { AdvancedFilterModel } from "src/types/dynamic";
// Utilities
import * as R from "ramda";
// Constants
import { advancedFilterTypesMapping } from "src/constants/advancedFilterTypes";
import { Dictionary, StringNumber } from "src/types/common";

/**
 * create params normalized data from advanced search expression
 *
 * @param {AdvancedFilterModel} expression
 */
export function getParamsFromExpression(expression: AdvancedFilterModel) {
	if (expression.type === "SELECT_BOX" || expression.type === "SELECT_BOX_COMBINATION") {
		return {
			values: expression.filter,
			type: expression.type,
		};
	} else if (!expression?.operator) {
		return {
			[advancedFilterTypesMapping[expression?.type]]: inRangeFilter(expression),
		};
	} else {
		return {
			[expression?.operator?.toLowerCase()]: expression?.conditions.map((condition) => ({
				[advancedFilterTypesMapping[condition.type]]: inRangeFilter(condition),
			})),
		};
	}
}

function inRangeFilter(
	expression: AdvancedFilterModel,
): Dictionary<StringNumber | any[]> | StringNumber | boolean | any[] {
	if (expression?.hasOwnProperty("filterTo") && expression?.hasOwnProperty("filter")) {
		return R.pick(["filterTo", "filter"])(expression);
	} else if (
		expression.type === "hasEmpty" ||
		expression.type === "completeEmpty" ||
		expression.type === "notEmpty"
	) {
		return true;
	} else {
		return expression.filter;
	}
}
