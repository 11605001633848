/**
 * @module Sagas/Collectors
 * @desc All Collectors sagas
 */
// Common components
import { Notification } from "src/components/Notification";
// Utilities
import { all, call, put, takeLatest } from "redux-saga/effects";
import { read } from "src/helpers/localStorage";
import { getDefaultDataVersions, getTsNumbersVersionsDataFromDocument } from "src/helpers/documentDataVersion";
import * as R from "ramda";
import i18n from "src/locales";
// Services
import CollectorsService from "src/services/http/endpoints/collectors";
// Actions
import CollectorsActions from "./collectors.actions";
// Types
import { Action } from "src/types/common";
// Constants
import * as types from "./collectors.constants";

function* getTableCollectors() {
	try {
		yield put(
			CollectorsActions.setLoading({
				type: "tableCollectors",
				status: false,
			}),
		);

		const result = yield call(() => CollectorsService.getTableCollectors());
		const tableCollectors = result.data?.payload?.documents;

		yield put(CollectorsActions.setTableCollectors(tableCollectors));

		const documents = tableCollectors.map((collector) => collector.document);

		yield put(CollectorsActions.setSelectedDocument(documents[0] || {}));
		yield put(CollectorsActions.setCollectorDocuments(documents));

		const tsNumberAndVersionsData = getTsNumbersVersionsDataFromDocument(documents);
		const { groupWithDedicatedDataTypes } = tsNumberAndVersionsData;

		yield put(CollectorsActions.setDataVersionsList(groupWithDedicatedDataTypes));

		const defaultDataVersion = getDefaultDataVersions(groupWithDedicatedDataTypes);

		const { defaultGroup, defaultDataType, defaultVersion } = defaultDataVersion;

		const currentTableCollector = read("tableCollectorDataVersion");

		yield put(CollectorsActions.setSelectedGroup(currentTableCollector?.Group || defaultGroup));
		yield put(CollectorsActions.setSelectedDataType(currentTableCollector?.DataType || defaultDataType));
		yield put(CollectorsActions.setSelectedVersion(currentTableCollector?.Version || defaultVersion));
		yield put(CollectorsActions.setVersionList([defaultVersion]));

		yield put(
			CollectorsActions.setLoading({
				type: "tableCollectors",
				status: false,
			}),
		);
	} catch (error) {
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(
				CollectorsActions.setLoading({
					type: "tableCollectors",
					status: false,
				}),
			);
		}
		yield put(
			CollectorsActions.setLoading({
				type: "tableCollectors",
				status: false,
			}),
		);
		console.log("DEBUG ~ file: documents.sagas.ts ~ line 12 ~ function*getPublishedDocuments ~ error", error);
	}
}

function* getExteractedTableCollector(action: Action<any>) {
	try {
		yield put(
			CollectorsActions.setLoading({
				type: "extractedTableCollector",
				status: true,
			}),
		);

		if (action.payload) {
			const result = yield call(() => CollectorsService.getExtractedTableCollector(action.payload));
			const extractedTableCollector = result.data?.payload?.table;

			yield put(CollectorsActions.setExtractedTableCollector(extractedTableCollector));
		} else {
			yield put(CollectorsActions.setExtractedTableCollector(null));
		}

		yield put(
			CollectorsActions.setLoading({
				type: "extractedTableCollector",
				status: false,
			}),
		);
	} catch (error) {
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(
				CollectorsActions.setLoading({
					type: "extractedTableCollector",
					status: false,
				}),
			);
		}
		yield put(
			CollectorsActions.setLoading({
				type: "extractedTableCollector",
				status: false,
			}),
		);
		console.log("DEBUG ~ file: documents.sagas.ts ~ line 12 ~ function*getPublishedDocuments ~ error", error);
	}
}

export default function* networkListeners(): Generator {
	yield all([takeLatest(types.SAGAS_GET_TABLE_COLLECTORS, getTableCollectors)]);
	yield all([takeLatest(types.SAGAS_GET_EXTRACTED_TABLE_COLLECTOR, getExteractedTableCollector)]);
}
