/**
 * @module Constants/Dyanmic
 * @desc All bands constant variables
 */

import { createReduxConstant } from "src/helpers";

const prefix = createReduxConstant("DYNAMIC");

/**
 * @module HEADER
 * @desc get and set bands header
 */
export const HEADER = prefix("HEADER");
export const SAGAS_HEADER = prefix("SAGAS_HEADER");
export const ORIGIN_HEADER = prefix("ORIGIN_HEADER");

/**
 * @module BODY
 * @desc Get and Set bands body
 */
export const BODY = prefix("BODY");
export const SAGAS_BODY = prefix("SAGA_BODY");

/**
 * @module NOTES
 * @desc Get and Set notes
 */
export const NOTES = prefix("NOTES");

/**
 * @module Loaded Count
 * @desc Set loaded rows count
 */
export const SET_LOADED_COUNT = prefix("SET_LOADED_COUNT");

/**
 * @module ROW_COUNT
 * @desc get bands row count
 */
export const ROW_COUNT = prefix("ROW_COUNT");
export const SAGAS_ROW_COUNT = prefix("SAGA_ROW_COUNT");

/**
 * @module LOADING
 * @desc set bands loading state
 */
export const NEW_LOADING = prefix("NEW_LOADING");
export const SAGAS_NEW_LOADING = prefix("SAGAS_NEW_LOADING");

/**
 * @module DATA_VERSION_FILTER_LIST
 * @desc Set data version filter list from header
 */
export const DATA_VERSION_FILTER_LIST = prefix("SET_DATA_VERSION_FILTER_LIST");
export const SAGAS_DATA_VERSION_FILTER_LIST = prefix("SAGAS_DATA_VERSION_FILTER_LIST");

/**
 * @module SET_SELECTED_DATA_VERSION
 * @desc Set selected data version
 */
export const SELECTED_DATA_VERSION = prefix("SELECTED_DATA_VERSION");

/**
 * @module COLLECTORS
 * @desc set data version modal open state
 */
export const SET_DATAVERSION_MODAL_OPEN = prefix("SET_DATAVERSION_MODAL_OPEN");

/**
 * @module SET_SELECTED_VISUALIZATION_DATA_VERSION
 * @desc Set selected data version for visualization
 */
export const SELECTED_VISUALIZATION_DATA_VERSION = prefix("SELECTED_VISUALIZATION_DATA_VERSION");
/**
 * @module SET_SELECTED_DATA_VERSION
 * @desc Set selected data version
 */
export const SELECTED_VISUALIZATION_COMPARE_DATA_VERSION = prefix("SELECTED_VISUALIZATION_COMPARE_DATA_VERSION");

/**
 * @module SAGAS_GET_CHART
 * @desc get bands chart
 */
export const SAGAS_CHART = prefix("SAGA_CHART");

/**
 * @module SET_CHART
 * @desc set bands chart
 */
export const SET_CHART = prefix("SET_CHART");

/**
 * @module SET_TABLE_PARAMS
 * @desc set band and band combo params
 */
export const SET_TABLE_PARAMS = prefix("SET_TABLE_PARAMS");

/**
 * @module SET_PAGE
 * @desc set band tables page
 */
export const SET_PAGE = prefix("SET_PAGE");

/**
 * @module SET_CURRENT_OPTIONS
 * @desc set current table options
 */
export const CURRENT_TABLE_OPTIONS = prefix("CURRENT_TABLE_OPTIONS");
export const SAGAS_CURRENT_TABLE_OPTIONS = prefix("SAGAS_CURRENT_TABLE_OPTIONS");

/**
 * @module RESET_SEARCH
 * @desc reset search
 */
export const SAGAS_RESET_SEARCH = prefix("SAGA_RESET_SEARCH");

/**
 * @module RESET_ALL
 * @desc reset all
 */
export const SAGAS_RESET_ALL = prefix("SAGA_RESET_ALL");

/**
 * @module EXPORT
 * @desc export
 */
export const SAGAS_EXPORT = prefix("SAGA_EXPORT");

/**
 * @module MEMOIZE_SNAPSHOT
 * @desc to handle whether get new data or not
 */
export const MEMOIZE_SNAPSHOT = prefix("MEMOIZE_SNAPSHOT");

/**
 * @module CLEARED_SEARCH
 * @desc to handle when the User clicked on Reset Search
 */
export const CLEARED_SEARCH = prefix("CLEARED_SEARCH");

/**
 * @module HANDLE_GRAPHICS
 * @desc set chartVisible state
 */
export const SET_IS_CHART_VISIBLE = prefix("SET_IS_CHART_VISIBLE");
/**
 * @module HANDLE_GRAPHICS
 * @desc set chartVisible state
 */
export const SET_IS_BANDS_CHART_VISIBLE = prefix("SET_IS_BANDS_CHART_VISIBLE");

// --- revision ---

/**
 * @module REVISION
 * @desc set isRevision state
 */
export const SET_IS_REVISION = prefix("SET_IS_REVISION");

/**
 * @module GROUP BY
 * @desc set isGroupBy state
 */
export const SET_IS_GROUP_BY = prefix("SET_IS_GROUP_BY");

/**
 * @module GROUP BY
 * @desc set isGroupBy state
 */
export const SET_IS_GROUP_BY_HEADERS_SET = prefix("SET_IS_GROUP_BY_HEADERS_SET");

/**
 * @module Exit GROUP BY
 * @desc set isGroupBy state
 */
export const SET_IS_EXIT_GROUP_BY = prefix("SET_IS_EXIT_GROUP_BY");

/**
 * @module REVISION
 * @desc set revision information
 */
export const SET_REVISION_INFO = prefix("SET_REVISION_INFO");

/**
 * @module GROUP BY
 * @desc set group by data
 */
export const SET_GROUP_BY_DATA = prefix("SET_GROUP_BY_DATA");

/**
 * @module Preview GROUP BY
 * @desc set preview group by data
 */
export const SET_PREVIEW_GROUP_BY_DATA = prefix("SET_PREVIEW_GROUP_BY_DATA");

/**
 * @module REVISION
 * @desc set row count revision
 */
export const SET_REVISION_ROW_COUNT = prefix("SET_REVISION_ROW_COUNT");

/**
 * @module EDIT_MODE
 * @desc toggle edit mode to show edit interface notification
 */
export const SET_EDIT_MODE = prefix("SET_EDIT_MODE");

/**
 * @module CELLS_EDITABLE
 * @desc toggle edit mode of cells
 */
export const SET_CELLS_EDITABLE = prefix("SET_CELLS_EDITABLE");
export const SAGAS_SET_CELLS_EDITABLE = prefix("SAGAS_SET_CELLS_EDITABLE");

/**
 * @module EDITED_FIELDS
 * @desc set edited fields values
 */
export const SET_EDITED_FIELDS = prefix("SET_EDITED_FIELDS");

/**
 * @module SUBMIT_EDIT
 * @desc submit edited cells
 */
export const SAGAS_SUBMIT_EDIT = prefix("SAGAS_SUBMIT_EDIT");

/**
 * @module SUBMIT_EDIT
 * @desc activate add mode
 */
export const SET_IS_ADD = prefix("SET_IS_ADD");

/**
 * @module SUBMIT_EDIT
 * @desc set add status
 */
export const SET_ADD_STATUS = prefix("SET_ADD_STATUS");
/**
 * @module MAKE_TABLE_CLICKABLE
 * @desc set table clickable
 */
export const MAKE_TABLE_CLICKABLE = prefix("MAKE_TABLE_CLICKABLE");

/**
 * @module HANDLE_GRAPHICS_TABLE
 * @desc get and set graphics table
 */
export const HANDLE_GRAPHICS_TABLE = prefix("HANDLE_GRAPHICS_TABLE");
export const SAGAS_HANDLE_GRAPHICS_TABLE = prefix("SAGA_HANDLE_GRAPHICS_TABLE");

/**
 * @module HANDLE_GRAPHICS
 * @desc get and set graphics data
 */
export const HANDLE_GRAPHICS = prefix("HANDLE_GRAPHICS");
export const SAGAS_HANDLE_GRAPHICS = prefix("SAGA_HANDLE_GRAPHICS");

/**
 * @module HANDLE_CUSTOM_GRAPHICS
 * @desc get and set graphics data
 */
export const SAGAS_HANDLE_CUSTOM_GRAPHICS = prefix("SAGAS_HANDLE_CUSTOM_GRAPHICS");

/**
 * @module SET_GRAPHICS_PARAMS
 * @desc set graphics params - uplink and downlink
 */
export const SET_GRAPHICS_PARAMS = prefix("SET_GRAPHICS_PARAMS");

/**
 * @module RESET_FACTORY
 * @desc RESET_FACTORY constant
 */
export const RESET_FACTORY = prefix("RESET_FACTORY");

/**
 * @module HANDLE_DATARATE_TABLE
 * @desc get and set datarate table
 */
export const HANDLE_DATARATE_TABLE = prefix("HANDLE_DATARATE_TABLE");
export const SAGAS_HANDLE_DATARATE_TABLE = prefix("SAGA_HANDLE_DATARATE_TABLE");

/**
 * @module HANDLE_DATARATE
 * @desc set DataRateVisible state
 */
export const SET_IS_DATARATE_VISIBLE = prefix("SET_IS_DATARATE_VISIBLE");

/**
 * @module HANDLE_DATARATE
 * @desc set dataRate params - upLinkBands and downLinkBands and version
 */
export const SET_DATARATE_PARAMS = prefix("SET_DATARATE_PARAMS");

/**
 * @module SET_BANDS_GRAPHICS_PARAMS
 * @desc set bands graphics params
 */
export const SET_BANDS_GRAPHICS_PARAMS = prefix("SET_BANDS_GRAPHICS_PARAMS");

/**
 * @module HANDLE_GRAPHICS
 * @desc get and set graphics data
 */
export const HANDLE_BANDS_GRAPHICS = prefix("HANDLE_BANDS_GRAPHICS");
export const SAGAS_HANDLE_BANDS_GRAPHICS = prefix("SAGA_HANDLE_BANDS_GRAPHICS");

/**
 * @module HANDLE_VISUALIZATION
 * @desc set VISUALIZATION state
 */
export const SET_IS_VISUALIZATION_VISIBLE = prefix("SET_IS_VISUALIZATION_VISIBLE");

/**
 * @module VISUALIZATION
 * @desc VISUALIZATION
 */
export const SAGAS_VISUALIZATION = prefix("SAGAS_VISUALIZATION");

/**
 * @module SET_VISUALIZATION
 * @desc SET VISUALIZATION
 */
export const SET_VISUALIZATION = prefix("SET_VISUALIZATION");

/**
 * @module SET_NOTE
 * @desc SET NOTE
 */
export const SET_NOTE = prefix("SET_NOTE");

/**
 * @module SET_GROUP_BY
 * @desc SET GROUP BY
 */
export const SET_GROUP_BY_VISIBILITY = prefix("SET_GROUP_BY");

/**
 * @module SAGAS_SAVE_PROFILE
 * @desc SAVE PROFILE
 */
export const SAGAS_SAVE_PROFILE = prefix("SAGAS_SAVE_PROFILE");

/**
 * @module SAGAS_GET_PROFILE
 * @desc GEt PROFILE
 */
export const SAGAS_GET_PROFILE = prefix("SAGAS_GET_PROFILE");

/**
 * @module SAGAS_DELETE_PROFILE
 * @desc DELETE PROFILE
 */
export const SAGAS_DELETE_PROFILE = prefix("SAGAS_DELETE_PROFILE");

/**
 * @module SET_PROFILES
 * @desc SET PROFILES
 */
export const SET_PROFILES = prefix("SET_PROFILES");

/**
 * @module SET_ENTITY
 * @desc set entity state
 */
export const SET_ENTITY = prefix("SET_ENTITY");
