/**
 * @module Actions/collectors
 * @desc All collectors actions
 */
// Types
import type { Action } from "src/types/common";
import type { TableCollector, GroupWithDedicatedDataTypes } from "src/types/collectors";
// Constants
import * as types from "./collectors.constants";
import { LoadingModel } from "src/types/dynamic";

class CollectorsActionsModel {
	/**
	 * get list of table collectors
	 * @return {Action}
	 */
	public getTableCollectors(): Action {
		return {
			type: types.SAGAS_GET_TABLE_COLLECTORS,
		};
	}

	/**
	 * get extracted table collector
	 * @return {Action}
	 */
	public getExtractedTableCollector(data: any): Action {
		return {
			type: types.SAGAS_GET_EXTRACTED_TABLE_COLLECTOR,
			payload: data,
		};
	}

	/**
	 * get list of table collectors
	 * @return {Action}
	 */
	public setTableCollectors(data: any): Action {
		return {
			type: types.SET_TABLE_COLLECTORS,
			payload: data,
		};
	}

	/**
	 * set selected table collector
	 * @return {Action}
	 */
	public setSelectedTableCollector(data: any): Action {
		return {
			type: types.SET_SELECTED_TABLE_COLLECTOR,
			payload: data,
		};
	}

	/**
	 * set list of extracted table collector
	 * @return {Action}
	 */
	public setExtractedTableCollector(data: any): Action {
		return {
			type: types.SET_EXTRACTED_TABLE_COLLECTOR,
			payload: data,
		};
	}

	/**
	 * set list of collector documents
	 * @return {Action<TableCollector[]>}
	 */
	public setCollectorDocuments(data: TableCollector[]): Action<TableCollector[]> {
		return {
			type: types.SET_COLLECTOR_DOCUMENTS,
			payload: data,
		};
	}

	/**
	 * set loading
	 * @param {LoadingModel} data
	 * @return {Action<LoadingModel>}
	 */
	public setLoading(data: LoadingModel): Action<LoadingModel> {
		return {
			type: types.SET_LOADING,
			payload: data,
		};
	}

	/**
	 * set ts numbers with dedicated version list
	 * @param {GroupWithDedicatedDataTypes[]} data
	 * @return {Action<GroupWithDedicatedDataTypes[]>}
	 */
	public setDataVersionsList(data: GroupWithDedicatedDataTypes[]): Action<GroupWithDedicatedDataTypes[]> {
		return {
			type: types.SET_DATAVERSIONS_LIST,
			payload: data,
		};
	}

	/**
	 * set selected ts number
	 * @param {string} data
	 * @return {Action<string>}
	 */
	public setSelectedGroup(data: string): Action<string> {
		return {
			type: types.SET_SELECTED_GROUP,
			payload: data,
		};
	}

	/**
	 * set selected ts number
	 * @param {string} data
	 * @return {Action<string>}
	 */
	public setSelectedDataType(data: string): Action<string> {
		return {
			type: types.SET_SELECTED_DATA_TYPE,
			payload: data,
		};
	}

	/**
	 * set selected version
	 * @param {string} data
	 * @return {Action<string>}
	 */
	public setSelectedVersion(data: string): Action<string> {
		return {
			type: types.SET_SELECTED_VERSION,
			payload: data,
		};
	}

	/**
	 * set version list
	 * @param {string[]} data
	 * @return {Action<string[]>}
	 */
	public setVersionList(data: string[]): Action<string[]> {
		return {
			type: types.SET_VERSION_LIST,
			payload: data,
		};
	}

	/**
	 * set data version modal open state
	 * @param {boolean} data
	 * @return {Action<boolean>}
	 */
	public setDataVersionModalOpened(data: boolean): Action<boolean> {
		return {
			type: types.SET_DATAVERSION_MODAL_OPEN,
			payload: data,
		};
	}

	/**
	 * set selected document
	 * @param {Partial<TableCollector>} data
	 * @return {Action<Partial<TableCollector>>}
	 */
	public setSelectedDocument(data: Partial<TableCollector>): Action<Partial<TableCollector>> {
		return {
			type: types.SET_SELECTED_DOCUMENT,
			payload: data,
		};
	}
}

const CollectorsActions = new CollectorsActionsModel();

export default CollectorsActions;
