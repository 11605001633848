import { all, fork } from "redux-saga/effects";
// Sagas entities
import DynamicSahas from "./Dynamic/dynamic.sagas";
import NotificationsSagas from "./Notifications/notifications.sagas";
import UserSagas from "./User/user.sagas";
import CommonSagas from "./Common/common.sagas";
import DocumentsSagas from "./Documents/documents.sagas";
import CollectorsSagas from "./Collectors/collectors.sagas";

/**
 * root Saga
 * In this case, we need to merge all redux-saga sagas together to observe all dispatched actions.
 */
export default function* root() {
	return yield all([
		fork(DynamicSahas),
		fork(NotificationsSagas),
		fork(UserSagas),
		fork(CommonSagas),
		fork(DocumentsSagas),
		fork(CollectorsSagas),
	]);
}
