/**
 * @module Sagas/Dynamic
 * @desc All Bands sagas
 */
// Sagas
import { all, put, takeLatest, call, select, takeEvery } from "redux-saga/effects";
// Services
import DynamicService from "src/services/http/endpoints/dynamic";
// Components
import { Notification } from "src/components/Notification";
// Utilities
import * as R from "ramda";
import { PAGE_SIZE } from "src/constants/agGrid";
import { changeSelectBoxValues, downloadFile, normalizeParams } from "src/helpers";
import { getDefaultHeaderFilters } from "src/helpers/headerFilters";
import { getDefaultSelectedDataVersions } from "src/pages/Dashboard/screens/utils/index.utils";
import { dynamicQueries } from "src/helpers/dataVersion";
import i18n from "src/locales";
import CacheManagement from "src/helpers/cacheManagement";
// Actions
import DynamicActions from "./dynamic.actions";
// Types
import type { StateNetwork } from "../index.reducer";
import type { DynamicState } from "./dynamic.reducer";
import type { DYNAMIC_ACTIONS_TYPES } from "./dynamic.types";
import type { Action, Dictionary, KeyName, LabelValue } from "src/types/common";
import type {
	Filters,
	FilterGroup,
	FloatingFilterData,
	TableOptions,
	EditedField,
	SortParams,
	BandComboDataRateTable,
	BandsGraphics,
} from "src/types/dynamic";
import type { UserState } from "../User/user.reducer";
// Constants
import * as types from "./dynamic.constants";
// Configs
import { ApplicationConfig } from "src/app.config";
import {
	DeleteProfileRequest,
	DynamicCustomGraphic,
	DynamicDataRateTable,
	DynamicGraphicsTable,
	GetProfileRequest,
	SaveProfileRequest,
} from "src/types/dynamic";

const { maxCache } = ApplicationConfig.pages.bandsCombination.chart;

const BandsCacheManagementGraphicsTable = new CacheManagement(maxCache);
const BandsCacheManagementGraphicsChart = new CacheManagement(maxCache);

const BandsCacheManagementDataRateTable = new CacheManagement(maxCache);

function shouldUpdateBody(
	currentBodyParams,
	bodySnapshot,
	bypassCache,
	pagesParams,
	loadedCount,
	filteredCount,
	isGroupBy,
) {
	return (
		((!R.equals(currentBodyParams, bodySnapshot) || bypassCache) &&
			!R.equals(R.omit(pagesParams, currentBodyParams), R.omit(pagesParams, bodySnapshot))) ||
		(!isGroupBy && loadedCount < filteredCount)
	);
}

function* dynamicHeader(action: DYNAMIC_ACTIONS_TYPES.HEADER_RETURN) {
	try {
		const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
		const userStore: UserState = yield select((state: StateNetwork) => state.user);

		if (!dynamicStore.snapshot.header || action.payload?.meta?.bypassCache) {
			yield put(
				DynamicActions.createSnapshot({
					header: true,
				}),
			);
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "header",
							status: true,
						},
						{
							type: "dataVersion",
							status: true,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);
			const {
				data: { payload },
			} = yield call(() => DynamicService.getHeaders(action.payload.entity));

			yield put(
				DynamicActions.dynamicHeader({
					data: payload,
					meta: {
						sagas: false,
						memoize: true,
					},
				}),
			);

			yield put(
				DynamicActions.dynamicOriginHeader({
					data: payload,
					meta: {
						sagas: false,
						memoize: true,
					},
				}),
			);

			yield put(
				DynamicActions.dataVersionFilterList({
					data: payload?.filters,
					meta: {
						sagas: false,
					},
				}),
			);
			// Get Bands Preview configuration from the Admin by Query Params
			const previewQueries = dynamicQueries();
			// ??? If we are in Preview mode, then all preview queries should be merged into the current Selected Data version list
			if (!R.isEmpty(previewQueries) && previewQueries.jobId) {
				const { jobId, ...dataVersions } = previewQueries;
				const previewVersions = Object.keys(dataVersions).map((item) => {
					return { key: item, value: dataVersions[item] };
				});
				// Put selected data versions to Store
				yield put(DynamicActions.selectedDataVersion(previewVersions));
			} else {
				const dataPairs = R.toPairs(payload.filters);
				const selectedData = getDefaultSelectedDataVersions(
					dataPairs,
					action.payload.entity,
					userStore.permissions,
				);
				// Store data versions
				yield put(DynamicActions.selectedDataVersion(selectedData));
			}
		}
	} catch (error) {
		console.log("function*bandsHeader -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "header",
							status: false,
						},
						{
							type: "dataVersion",
							status: false,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);
		}
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "header",
						status: false,
					},
					{
						type: "dataVersion",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}

function* dynamicBody(action: DYNAMIC_ACTIONS_TYPES.BODY_RETURN) {
	const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
	try {
		const params = action.payload?.data?.params;
		let currentBodyParams = normalizeParams(params || {});
		const pagesParams = ["size", "page"];
		const dataVersionsKey = [
			"type",
			"srcVersion",
			"dstVersion",
			...Object.keys(dynamicStore.normalizedDataVersion),
		];
		if (dynamicStore.clearedSearch) {
			currentBodyParams = R.pick([...dataVersionsKey, ...pagesParams, "sortModel"], currentBodyParams);
			currentBodyParams.page = 1;
		}

		// Get Bands Preview configuration(just jobId) from the Admin by Query Params
		const previewQueries = dynamicQueries();
		// ??? If we are in Preview mode, then all preview queries should be merged into the current Table's body params
		if (!R.isEmpty(previewQueries) && previewQueries.jobId) {
			currentBodyParams = { ...currentBodyParams, jobId: previewQueries.jobId };
		}

		const updateBodyCondition =
			shouldUpdateBody(
				{ isEditMode: dynamicStore.isEditMode, ...currentBodyParams },
				dynamicStore.snapshot.body,
				action.payload?.meta?.bypassCache,
				pagesParams,
				dynamicStore.loadedCount,
				dynamicStore.rowCount.filteredCount,
				dynamicStore.isGroupBy,
			) ||
			(dynamicStore.isGroupBy && !dynamicStore.isGroupByHeadersSet) ||
			dynamicStore.isExitGroupBy;

		if (updateBodyCondition) {
			// Show the Table's loading overlay
			window.dynamicGridApi?.setGridOption("loading", true);

			if (!dynamicStore.isGroupBy && currentBodyParams.page === 1) {
				yield put(
					DynamicActions.rowCount({
						data: R.omit(pagesParams, currentBodyParams),
						meta: {
							sagas: true,
							bypassCache: true,
							revision: dynamicStore.isRevision,
						},
						entity: action.payload.entity,
					}),
				);
			}

			yield put(
				DynamicActions.createSnapshot({
					body: { isEditMode: dynamicStore.isEditMode, ...currentBodyParams },
				}),
			);

			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "body",
							status: true,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);

			let payload: any[];
			let selectBoxValues: Record<string, KeyName[]>;

			if (dynamicStore.isGroupBy) {
				const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
				const groupByParams = {
					...dynamicStore.groupByData,
					page: currentBodyParams.page,
					size: currentBodyParams.size,
					sortModel: currentBodyParams.sortModel,
				};

				const data = yield call(() =>
					DynamicService.getGroupBy(action.payload.entity, R.omit(["entity"], groupByParams)),
				);

				payload = data.data.payload.data;

				if (currentBodyParams.page === 1) {
					yield put(
						DynamicActions.dynamicHeader({
							data: {
								filters: dynamicStore.header?.filters,
								filtersColumns: dynamicStore.header?.filtersColumns,
								groups: dynamicStore.header?.groups,
								dataVersionMap: dynamicStore.header?.dataVersionMap,
								headers: data.data.payload.headers,
							},
							meta: {
								sagas: false,
								memoize: true,
							},
						}),
					);
					yield put(
						DynamicActions.rowCount({
							data: data.data.payload.rowCount,
							meta: {
								sagas: false,
								memoize: true,
							},
						}),
					);
					yield put(DynamicActions.setIsGroupBYHeadersSet(true));
				}
			} else if (dynamicStore.isRevision) {
				const dataVersion = Object.keys(dynamicStore.dataVersions).find(
					(item) => item === currentBodyParams.type || item.toLowerCase() === currentBodyParams.type,
				);
				currentBodyParams.type = dataVersion;
				const { data } = yield call(() =>
					DynamicService.getRevisionBody(action.payload.entity, currentBodyParams),
				);
				payload = data.payload.revisionHistory;
			} else {
				const { data } = yield call(() =>
					DynamicService.getDynamicBody(action.payload.entity, currentBodyParams, dynamicStore.isEditMode),
				);

				if (currentBodyParams.page === 1 && data.payload.notes) {
					yield put(
						DynamicActions.notes({
							data: data.payload.notes,
							meta: {
								sagas: true,
							},
						}),
					);
				}

				payload = data.payload.data;
				selectBoxValues = data.payload.selectBoxValues ?? {};
			}

			const newDynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
			yield put(DynamicActions.loadedCount(payload.length + newDynamicStore.loadedCount));
			const newPayloadWithId = R.map<Record<string, any>, Record<string, any>>((row) =>
				R.mergeRight({ id: Math.random().toString(16).slice(2) })(row),
			)(payload);
			yield put(
				DynamicActions.body({
					data: {
						data: newPayloadWithId,
					},
					meta: {
						sagas: false,
						memoize: true,
					},
				}),
			);

			changeSelectBoxValues(selectBoxValues, action.payload.entity);

			// ! Maybe it has some side-effects at the future and we have to test the whole table functionality
			// ! and make sure there is no side-effect by setting-up the table params into the Redux
			yield put(DynamicActions.setParams(currentBodyParams));
			yield put(DynamicActions.clearedSearchStatus(false));

			if (
				// If the Current DataVersions object is not equals to the Prev DataVersions object, then we have to clear the Table's cache.
				!R.equals(
					R.pick(dataVersionsKey, currentBodyParams),
					R.pick(dataVersionsKey, dynamicStore.snapshot.body),
				)
			) {
				window.dynamicGridApi.expireValueCache();
				window.dynamicGridApi.refreshCells();
				window.dynamicGridApi.redrawRows();
			}
		}
	} catch (error) {
		console.log("Body Error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			// Hide the Table's loading overlay
			window.dynamicGridApi?.hideOverlay();

			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "body",
							status: false,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);

			if (dynamicStore.isGroupBy) {
				yield put(
					DynamicActions.loading({
						data: [
							{
								type: "groupBy",
								status: false,
							},
						],
						meta: {
							sagas: true,
						},
					}),
				);
			} else if (dynamicStore.isExitGroupBy) {
				yield put(DynamicActions.setIsExitGroupBY(false));
			}
		}
	} finally {
		// Hide the Table's loading overlay
		window.dynamicGridApi?.setGridOption("loading", false);

		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "body",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);

		if (dynamicStore.isGroupBy) {
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "groupBy",
							status: false,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);
		} else if (dynamicStore.isExitGroupBy) {
			yield put(DynamicActions.setIsExitGroupBY(false));
		}
	}
}

function* rowCount(action: DYNAMIC_ACTIONS_TYPES.ROW_COUNT_RETURN) {
	const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
	try {
		yield put(DynamicActions.loadedCount(0));
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "rowCount",
						status: true,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);

		const currentTableParams = action.payload.data;

		if (!action.payload.meta.revision) {
			const { data } = yield call(() => DynamicService.getRowCount(action.payload.entity, currentTableParams));
			const rowCount = data.payload;
			yield put(
				DynamicActions.rowCount({
					data: rowCount,
					meta: {
						sagas: false,
						memoize: true,
					},
				}),
			);
		} else {
			const dataVersion = Object.keys(dynamicStore.dataVersions).find(
				(item) => item === currentTableParams["type"] || item.toLowerCase() === currentTableParams["type"],
			);
			currentTableParams["type"] = dataVersion;
			const { data } = yield call(() =>
				DynamicService.getRevisionRowCount(action.payload.entity, currentTableParams),
			);
			const revisionRowCount = data.payload;
			yield put(
				DynamicActions.setRevisionRowCount({
					data: revisionRowCount,
					meta: {
						sagas: false,
						memoize: true,
					},
				}),
			);
		}
	} catch (error) {
		console.log("function*bandsRowCount -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "rowCount",
							status: false,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);
		}
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "rowCount",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}

function* resetSearch() {
	try {
		yield put(DynamicActions.clearedSearchStatus(true));
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "dataVersion",
						status: true,
					},
					{
						type: "filter",
						status: true,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
		const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
		const defaultCurrentOptions: TableOptions = {
			dataVersions: dynamicStore.currentTableOptions.dataVersions,
			columnFilters: [],
			columnSorts: dynamicStore.currentTableOptions.columnSorts,
			selectedHeaderVisibleColumns: dynamicStore.currentTableOptions.selectedHeaderVisibleColumns,
			defaultHeaderVisibleColumns: dynamicStore.currentTableOptions.defaultHeaderVisibleColumns,
		};
		yield put(
			DynamicActions.currentTableOptions({
				data: defaultCurrentOptions,
				meta: {
					sagas: false,
				},
			}),
		);
		yield put(DynamicActions.setPage(1));
		yield put(DynamicActions.selectedDataVersion(dynamicStore.selectedDataVersion));
		yield put(DynamicActions.loadedCount(0));

		window.dynamicGridApi?.setFilterModel?.(null);
		// notifies the ag-grid that the filter model is changed
		window.dynamicGridApi?.onFilterChanged?.();
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "dataVersion",
						status: false,
					},
					{
						type: "filter",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}

function* resetAll(action: DYNAMIC_ACTIONS_TYPES.RESET_ALL_PAYLOAD) {
	try {
		yield put(DynamicActions.clearedSearchStatus(true));
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "dataVersion",
						status: true,
					},
					{
						type: "filter",
						status: true,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);

		const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
		const userStore: UserState = yield select((state: StateNetwork) => state.user);

		const defaultFilterColumns: FloatingFilterData[] = [];
		const defaultSortColumns: SortParams[] = [];
		const dataPairs = R.toPairs(dynamicStore.dataVersions as Filters);
		const defaultDataVersions = getDefaultSelectedDataVersions(
			dataPairs,
			action.payload?.entity,
			userStore.permissions,
		);
		const mappedDefaultDataVersions = defaultDataVersions.reduce(
			(r, acc) => Object.assign(r, { [acc.key]: acc.value }),
			{},
		);

		const defaultHeaderFilters = getDefaultHeaderFilters(dynamicStore.header?.groups);

		const defaultHeaderVisibleColumns = R.map<FilterGroup, LabelValue>(
			(item) => ({
				label: item.groupName,
				value: item.groupId,
			}),
			dynamicStore.header?.groups ?? [],
		);
		const defaultCurrentOptions: TableOptions = {
			dataVersions: mappedDefaultDataVersions,
			columnFilters: defaultFilterColumns,
			columnSorts: defaultSortColumns,
			selectedHeaderVisibleColumns: defaultHeaderFilters,
			defaultHeaderVisibleColumns: defaultHeaderVisibleColumns,
		};

		const defaultParams = {
			...(defaultCurrentOptions.dataVersions || {}),
			page: 1,
			size: PAGE_SIZE,
		};
		yield put(DynamicActions.setParams(defaultParams));
		yield put(DynamicActions.setPage(1));

		yield put(
			DynamicActions.currentTableOptions({
				data: defaultCurrentOptions,
				meta: {
					sagas: false,
				},
			}),
		);
		yield put(DynamicActions.selectedDataVersion(defaultDataVersions));
		yield put(DynamicActions.loadedCount(0));

		window.dynamicGridApi?.setFilterModel?.(null);
		window.dynamicColumnApi?.applyColumnState({
			state: null,
			defaultState: { sort: null, sortIndex: null },
		});
		window.dynamicGridApi?.onFilterChanged();
	} catch (error) {
		console.log("DEBUG -> function*resetAll -> error", error);
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "dataVersion",
						status: false,
					},
					{
						type: "filter",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}
function* getExportResult({
	entity,
	isRevision,
	isGroupBy,
	dataVersions,
	revisionInfo,
	hideGroups,
	bodyFilters,
	groupByData,
}: {
	entity: string;
	isRevision: boolean;
	isGroupBy: boolean;
	dataVersions: any;
	revisionInfo: any;
	hideGroups: string[];
	bodyFilters: any;
	groupByData: any;
}) {
	if (isRevision) {
		return yield call(() =>
			DynamicService.exportData(entity, revisionInfo, isHideGroups(hideGroups), bodyFilters, false, true),
		);
	}

	if (isGroupBy) {
		const combinedFilters = { ...bodyFilters, ...groupByData };
		return yield call(() =>
			DynamicService.exportData(
				entity,
				dataVersions,
				isHideGroups(hideGroups),
				combinedFilters,
				false,
				false,
				true,
			),
		);
	}

	return yield call(() => DynamicService.exportData(entity, dataVersions, isHideGroups(hideGroups), bodyFilters));
}

function isHideGroups(hideGroups: string[]) {
	return !R.isEmpty(hideGroups) ? hideGroups : undefined;
}

function* exportData(action: DYNAMIC_ACTIONS_TYPES.EXPORT_TABLE_RETURN) {
	try {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "export",
						status: true,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
		const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
		const { selectedHeaderVisibleColumns: selectedFilterGroups, dataVersions } = dynamicStore.currentTableOptions;
		const plainFilterGroups = getDefaultHeaderFilters(dynamicStore.header?.groups || []);
		const hideGroups = R.without(selectedFilterGroups, plainFilterGroups);

		const columnFilters = dynamicStore.currentTableOptions?.columnFilters || {};

		let bodyFilters = normalizeParams({
			filterModel: columnFilters,
			sortModel: dynamicStore.tableParams.sortModel || undefined,
		} as Dictionary);
		//@ts-ignore
		const entity = action.payload.payload.data.entity;
		const isRevision = dynamicStore.isRevision;
		const isGroupBy = dynamicStore.isGroupBy;

		const result = yield* getExportResult({
			entity,
			isRevision,
			isGroupBy,
			dataVersions,
			revisionInfo: dynamicStore.revisionInfo,
			hideGroups,
			bodyFilters,
			groupByData: dynamicStore.groupByData,
		});

		downloadFile(
			result.data,
			result.headers["content-disposition"].split('"')[1],
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
		);
	} catch (error) {
		console.log("DEBUG ~ file: bands.sagas.ts ~ line 363 ~ function*exportBands ~ error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "export",
							status: false,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);
		}
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "export",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}

function* loadingStatus(action: DYNAMIC_ACTIONS_TYPES.LOADING_RETURN) {
	for (const loading of action.payload.data) {
		yield put(
			DynamicActions.loading({
				data: [loading],
				meta: {
					sagas: false,
				},
			}),
		);
	}
}

function* cellsEditable(action: DYNAMIC_ACTIONS_TYPES.ENABLE_EDIT_MODE_RETURN) {
	yield put(DynamicActions.setPage(1));
	yield put(DynamicActions.loadedCount(0));

	yield put(
		DynamicActions.setCellsEditable({
			data: action.payload.data,
			meta: {
				sagas: false,
			},
		}),
	);

	yield put(
		DynamicActions.dynamicHeader({
			data: null,
			meta: {
				sagas: true,
				memoize: true,
				bypassCache: true,
			},
		}),
	);
}

function getModifiedEditData(data) {
	return R.map((item: any) => {
		const omittedItem = R.omit(["id"], item);
		return R.map((subItem) => {
			const condition = subItem?.rawValue ? subItem?.rawValue : subItem?.value;
			return R.is(Object, subItem) ? condition : subItem;
		}, omittedItem as any);
	}, data);
}

function* submitEditedCells(action: Action<Record<string, any>[]>) {
	try {
		window.dynamicGridApi?.showLoadingOverlay();
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "edit",
						status: true,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
		const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);

		if (dynamicStore.isAdd) {
			const addModifiedData = getModifiedEditData(action.payload);
			yield call(() => DynamicService.addRow("bands", addModifiedData));

			yield put(DynamicActions.setAddStatus("success"));

			yield put(DynamicActions.setEditMode(false));
			yield put(DynamicActions.activateAdd(false));

			return;
		}

		const uniqueEditedRows = R.pipe<EditedField[], number[], number[]>(
			R.map((item) => item.rowIndex),
			R.uniq,
		)(dynamicStore.editedFieldsValues);

		const changedBodyRows = R.addIndex(R.filter)((_, index) => R.includes(index, uniqueEditedRows))(
			dynamicStore.body,
		);
		const editModifiedData = getModifiedEditData(changedBodyRows);

		yield call(() => DynamicService.editRow("bands", editModifiedData));

		yield put(
			DynamicActions.setCellsEditable({
				data: false,
				meta: {
					sagas: true,
				},
			}),
		);
		yield put(DynamicActions.setEditMode(false));
	} catch (error) {
		console.log("DEBUG -> function*submitEditedCells -> error", error);
		yield put(DynamicActions.setAddStatus("error"));
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "edit",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
		window.dynamicGridApi?.hideOverlay();
	}
}

function* graphicsTable(action: DYNAMIC_ACTIONS_TYPES.GRAPHICS_TABLE_RETURN) {
	try {
		const data = R.omit(["selectedId"], action.payload.data);
		const selectedId = (action.payload.data as DynamicGraphicsTable)?.selectedId;

		const idExists = BandsCacheManagementGraphicsTable.isExist(selectedId);

		if (idExists) {
			// NOTE: if item exist in stack use that
			const foundItem = BandsCacheManagementGraphicsTable.search(selectedId);

			yield put(
				DynamicActions.handleGraphicsTable({
					data: foundItem,
					meta: { sagas: false },
				}),
			);
		} else {
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "graphicsTable",
							status: true,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);

			const {
				data: { payload },
			} = yield call(() =>
				DynamicService.getDynamicFunction({
					...data,
					functionName: "intermodulationTable",
				} as DynamicGraphicsTable),
			);

			const requestData = {
				body: payload?.data,
				headers: payload?.headers,
				defaultMaxOrder: payload?.defaultMaxOrder,
			};

			BandsCacheManagementGraphicsTable.insert(selectedId, requestData);

			yield put(
				DynamicActions.handleGraphicsTable({
					data: requestData,
					meta: { sagas: false },
				}),
			);
		}
	} catch (error) {
		console.log("DEBUG: -> function*graphicsTable -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "graphicsTable",
							status: false,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);
		}
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "graphicsTable",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}

function* graphicsChart(action: DYNAMIC_ACTIONS_TYPES.GRAPHICS_TABLE_RETURN) {
	try {
		const data = R.omit<DynamicGraphicsTable, "selectedId">(
			["selectedId"],
			action.payload.data as DynamicGraphicsTable,
		);

		const selectedId = (action.payload.data as DynamicGraphicsTable)?.selectedId;

		const idExists = BandsCacheManagementGraphicsChart.isExist(selectedId);

		if (idExists) {
			// NOTE: if item exist in stack use that
			const foundItem = BandsCacheManagementGraphicsChart.search(selectedId);

			yield put(
				DynamicActions.handleGraphics({
					data: foundItem,
					meta: { sagas: false },
				}),
			);
		} else {
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "chart",
							status: true,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);
			const {
				data: { payload },
			} = yield call(() =>
				DynamicService.getDynamicFunction({
					...data,
					functionName: "intermodulationBody",
				}),
			);

			BandsCacheManagementGraphicsChart.insert(selectedId, payload);
			yield put(
				DynamicActions.handleGraphics({
					data: payload,
					meta: { sagas: false },
				}),
			);
		}
	} catch (error) {
		console.log("DEBUG: -> function*graphicsChart -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "chart",
							status: false,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);
		}
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "chart",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}

function* customGraphicsChart(action: DYNAMIC_ACTIONS_TYPES.GRAPHICS_TABLE_RETURN) {
	try {
		const data = R.omit<DynamicCustomGraphic, "selectedId">(
			["selectedId"],
			action.payload.data as DynamicCustomGraphic,
		);

		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "chart",
						status: true,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
		const {
			data: { payload },
		} = yield call(() => DynamicService.postBandComboGraphics(data));

		yield put(
			DynamicActions.handleGraphics({
				data: payload,
				meta: { sagas: false },
			}),
		);
	} catch (error) {
		console.log("DEBUG: -> function*graphicsChart -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "chart",
							status: false,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);
		}
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "chart",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}

function* dataRateTable(action: DYNAMIC_ACTIONS_TYPES.DATARATE_TABLE_RETURN) {
	try {
		const data = R.omit(["selectedId"], action.payload.data);
		const selectedId = (action.payload.data as BandComboDataRateTable)?.selectedId;

		const idExists = BandsCacheManagementDataRateTable.isExist(selectedId);

		if (idExists) {
			// NOTE: if item exist in stack use that
			const foundItem = BandsCacheManagementDataRateTable.search(selectedId);

			yield put(
				DynamicActions.handleDataRateTable({
					data: foundItem,
					meta: { sagas: false },
				}),
			);
		} else {
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "dataRateTable",
							status: true,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);

			const {
				data: { payload },
			} = yield call(() =>
				DynamicService.getDynamicFunction({
					...data,
					functionName: "dataRatePopup",
				} as DynamicDataRateTable),
			);

			const requestData = {
				aggDataRate: payload?.aggDataRate,
				body: payload?.data,
				headers: payload?.headers,
			};

			BandsCacheManagementDataRateTable.insert(selectedId, requestData);

			yield put(
				DynamicActions.handleDataRateTable({
					data: requestData,
					meta: { sagas: false },
				}),
			);
		}
	} catch (error) {
		console.log("DEBUG: -> function*dataRateTable -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(
				DynamicActions.loading({
					data: [
						{
							type: "dataRateTable",
							status: false,
						},
					],
					meta: {
						sagas: true,
					},
				}),
			);
		}
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "dataRateTable",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}

function* bandsGraphics(action: DYNAMIC_ACTIONS_TYPES.BANDS_GRAPHICS_RETURN) {
	try {
		const data = R.omit(["selectedId"], action.payload.data);

		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "chart",
						status: true,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
		const {
			data: { payload },
		} = yield call(() => DynamicService.getBandGraphics(data as BandsGraphics));

		const requestData = {
			bands: payload?.bands,
			higlightRanges: payload?.higlightRanges,
		};

		yield put(DynamicActions.setBandsChart(requestData));
	} catch (error) {
		console.log("DEBUG: -> function*graphicsTable -> error", error);
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "chart",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}

function* dynamicVisualization(action: DYNAMIC_ACTIONS_TYPES.DYNAMIC_VISUALIZATION) {
	try {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "visualization",
						status: true,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
		//@ts-ignore
		const versions = action.payload.payload.data.versions.reduce((acc, cur) => {
			acc[cur.key] = cur.value;
			return acc;
		}, {});
		//@ts-ignore
		const versions2 = action.payload.payload.data.versions2?.reduce((acc, cur) => {
			acc[cur.key] = cur.value;
			return acc;
		}, {});
		//@ts-ignore
		const filters = R.omit(["versions", "entity"], action.payload.payload.data);
		const {
			data: { payload },
		} = yield call(() =>
			DynamicService.visualization(
				//@ts-ignore
				action.payload.payload.data.entity,
				versions,
				versions2,
				filters,
			),
		);
		yield put(DynamicActions.setVisualization(payload));
	} catch (error) {
		console.log("DEBUG ~ file: dynamic.sagas.ts ~ line 1187 ~ function*dynamicVisualization ~ error", error);
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "visualization",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	} finally {
		yield put(
			DynamicActions.loading({
				data: [
					{
						type: "visualization",
						status: false,
					},
				],
				meta: {
					sagas: true,
				},
			}),
		);
	}
}

function* saveProfiles(action: Action<SaveProfileRequest>) {
	try {
		const {
			data: { payload },
		} = yield call(() =>
			DynamicService.postSaveProfile(
				//@ts-ignore
				action.payload,
			),
		);
		Notification({ message: `Profile «${payload.profile.name}» saved successfully!`, type: "success" });
		const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
		const profilesLists = dynamicStore.profiles;
		if (action.payload?.type === "entity") {
			profilesLists.entity = [...profilesLists.entity, payload.profile];
		} else {
			profilesLists.visualization = [...profilesLists.visualization, payload.profile];
		}
		yield put(DynamicActions.setProfilesList({ data: profilesLists, meta: {} }));
	} catch (error) {
		console.log("DEBUG ~ file: dynamic.sagas.ts ~ line 1264 ~ function*saveProfiles ~ error", error);
		Notification({ message: `Profile save failed!`, type: "error" });
	}
}

function* deleteProfile(action: Action<DeleteProfileRequest>) {
	try {
		const {
			data: { message },
		} = yield call(() =>
			DynamicService.deleteProfile(
				//@ts-ignore
				action.payload.id,
			),
		);

		Notification({ message, type: "success" });

		const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
		const profilesLists = dynamicStore.profiles;
		profilesLists.entity = profilesLists.entity.filter((profile) => profile._id !== action.payload.id);
		yield put(DynamicActions.setProfilesList({ data: profilesLists, meta: {} }));
	} catch (error) {
		console.log("DEBUG ~ file: dynamic.sagas.ts ~ line 1264 ~ function*saveProfiles ~ error", error);
		Notification({ message: `Delete profile failed!`, type: "error" });
	}
}

function* getProfiles(action: Action<GetProfileRequest>) {
	try {
		const {
			data: { payload },
		} = yield call(() =>
			DynamicService.getSavedProfiles(
				//@ts-ignore
				action.payload,
			),
		);
		const dynamicStore: DynamicState = yield select((state: StateNetwork) => state.dynamic);
		const profilesLists = dynamicStore.profiles;
		if (action.payload?.type === "entity") {
			profilesLists.entity = payload.profiles;
		} else {
			profilesLists.visualization = payload.profiles;
		}
		yield put(DynamicActions.setProfilesList({ data: profilesLists, meta: {} }));
	} catch (error) {
		console.log("DEBUG ~ file: dynamic.sagas.ts ~ line 1264 ~ function*saveProfiles ~ error", error);
	}
}

export default function* networkListeners() {
	yield all([
		takeEvery(types.SAGAS_BODY, dynamicBody),
		takeLatest(types.SAGAS_ROW_COUNT, rowCount),
		takeLatest(types.SAGAS_HEADER, dynamicHeader),
		takeLatest(types.SAGAS_RESET_ALL, resetAll),
		takeLatest(types.SAGAS_EXPORT, exportData),
		takeLatest(types.SAGAS_RESET_SEARCH, resetSearch),
		takeLatest(types.SAGAS_NEW_LOADING, loadingStatus),
		takeLatest(types.SAGAS_SET_CELLS_EDITABLE, cellsEditable),
		takeLatest(types.SAGAS_SUBMIT_EDIT, submitEditedCells),
		takeLatest(types.SAGAS_HANDLE_GRAPHICS_TABLE, graphicsTable),
		takeLatest(types.SAGAS_HANDLE_GRAPHICS, graphicsChart),
		takeLatest(types.SAGAS_HANDLE_CUSTOM_GRAPHICS, customGraphicsChart),
		takeLatest(types.SAGAS_HANDLE_DATARATE_TABLE, dataRateTable),
		takeLatest(types.SAGAS_HANDLE_BANDS_GRAPHICS, bandsGraphics),
		takeLatest(types.SAGAS_VISUALIZATION, dynamicVisualization),
		takeLatest(types.SAGAS_SAVE_PROFILE, saveProfiles),
		takeLatest(types.SAGAS_GET_PROFILE, getProfiles),
		takeLatest(types.SAGAS_DELETE_PROFILE, deleteProfile),
	]);
}
