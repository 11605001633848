/**
 * @module Sagas/Documents
 * @desc All Documents sagas
 */
// Common components
import { Notification } from "src/components/Notification";
// Utilities
import { all, call, put, takeLatest } from "redux-saga/effects";
import { read } from "src/helpers/localStorage";
import { getDefaultDataVersions, getTsNumbersVersionsDataFromDocument } from "src/helpers/documentDataVersion";
import * as R from "ramda";
import i18n from "src/locales";
import { parseQuery } from "src/helpers/url";
// Services
import DocumentsService from "src/services/http/endpoints/documents";
// Actions
import DocumentsActions from "./documents.actions";
// Types
import type { Document, ReadDocumentData, OutlineData } from "src/types/documents";
import type { Action } from "src/types/common";
import type { PDFDocumentProxy, PDFTreeNode } from "pdfjs-dist/webpack";
// Constants
import * as types from "./documents.constants";

// this function generate the left, top of page for outlines and scrolling it
async function generateOutlineData(outline: PDFTreeNode[], pdfDocument: any, pdfLinkService: any) {
	const data: Array<OutlineData> = [];
	outline.forEach(async (item: any) => {
		if (item.dest) {
			let pageNumber: number;
			// it's from the pdf.js code. for the first time, if the item.dist is null, below codes will create the dest
			const tempPage = pdfLinkService._cachedPageNumber(item.dest[0]);
			if (!tempPage) {
				const pageIndex = await pdfDocument.getPageIndex(item.dest[0]);
				pdfLinkService.cachePageRef(pageIndex + 1, item.dest[0]);
				pageNumber = pdfLinkService._cachedPageNumber(item.dest[0]);
			}
			data.push({
				hashedDest: item.dest[0],
				left: item.dest[2],
				top: item.dest[3],
				pageNumber: !tempPage ? pageNumber : tempPage,
			});
		}
		if (item.items) {
			data.push(...(await generateOutlineData(item.items, pdfDocument, pdfLinkService)));
		}
	});
	return data;
}

function checkOutline(outlineWithPageNumber: any, location: any, active: any, setActive: any) {
	for (let i = 0; i < outlineWithPageNumber.length; i++) {
		const item = outlineWithPageNumber[i];
		if (
			item.pageNumber > location.pageNumber ||
			(item.pageNumber === location.pageNumber && item.top < location.top)
		) {
			if (!active && i !== 0) {
				setActive(outlineWithPageNumber[i - 1]);
				break;
			}
		}
		if (item.top >= location.top && item.pageNumber === location.pageNumber) {
			setActive(item);
		}
	}
}

function* getPublishedDocuments() {
	try {
		yield put(
			DocumentsActions.setLoading({
				type: "dataVersion",
				status: true,
			}),
		);

		const result = yield call(() => DocumentsService.getPublished());
		const documents: Document[] = result.data?.payload?.documents;

		yield put(DocumentsActions.setPublishedDocuments(documents));

		const tsNumberAndVersionsData = getTsNumbersVersionsDataFromDocument(documents);
		const { tsNumberToDocumentTypeMapping, groupWithDedicatedDataTypes } = tsNumberAndVersionsData;
		const uniqueTsNumbers = groupWithDedicatedDataTypes.map((item) => item.group);

		yield put(DocumentsActions.setTsNumberToDocumentTypeMapping(tsNumberToDocumentTypeMapping));

		yield put(DocumentsActions.setTsNumberList(uniqueTsNumbers));

		yield put(DocumentsActions.setDataVersionsList(groupWithDedicatedDataTypes));

		const defaultDataVersion = getDefaultDataVersions(groupWithDedicatedDataTypes);

		const { defaultGroup, defaultDataType, defaultVersion } = defaultDataVersion;

		const currentSelectedGroup = read("documentSelectedGroup");

		const currentSelectedDataType = read("documentSelectedDataType");

		const currentSelectedVersion = read("documentSelectedVersion");

		yield put(DocumentsActions.setSelectedGroup(currentSelectedGroup || defaultGroup));
		yield put(DocumentsActions.setSelectedDataType(currentSelectedDataType || defaultDataType));
		yield put(DocumentsActions.setSelectedVersion(currentSelectedVersion || defaultVersion));
		yield put(DocumentsActions.setVersionList([defaultVersion]));

		yield put(
			DocumentsActions.setLoading({
				type: "dataVersion",
				status: false,
			}),
		);
	} catch (error) {
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(
				DocumentsActions.setLoading({
					type: "dataVersion",
					status: false,
				}),
			);
		}
		yield put(
			DocumentsActions.setLoading({
				type: "dataVersion",
				status: false,
			}),
		);
		console.log("DEBUG ~ file: documents.sagas.ts ~ line 12 ~ function*getPublishedDocuments ~ error", error);
	}
}

function* readDocument(action: Action<ReadDocumentData>) {
	try {
		const payload = action.payload;

		yield put(
			DocumentsActions.setLoading({
				type: "pdf",
				status: true,
			}),
		);

		const pdfDocument: PDFDocumentProxy = yield payload?.loadingTask.promise;

		const outline: PDFTreeNode[] = yield pdfDocument.getOutline();
		yield put(DocumentsActions.setOutline(outline));

		yield put(
			DocumentsActions.setLoading({
				type: "pdf",
				status: false,
			}),
		);
		payload?.pdfViewer?.setDocument(pdfDocument);
		payload?.pdfLinkService.setDocument(pdfDocument, null);

		if (outline && pdfDocument && payload?.pdfLinkService) {
			const outlineWithPageNumber = yield generateOutlineData(outline, pdfDocument, payload?.pdfLinkService);

			// this is an event listener for scrolling the outline. it will check the current viewArea with
			// all of the outline positions, then if top of the viewArea is between 2 of outlines, it will highlight
			// the previous outline in the table of content.
			payload?.eventBus?.on("updateviewarea", (viewArea: any) => {
				let active: Record<string, unknown> | null = null;
				const setActive = (value: any) => {
					active = value;
				};

				const { location } = viewArea;
				if (location) {
					checkOutline(outlineWithPageNumber, location, active, setActive);
					// if the viewArea is after the last outline, it will highlight the last outline.
					if (
						outlineWithPageNumber[outlineWithPageNumber.length - 1] &&
						!active &&
						location.pageNumber > outlineWithPageNumber[outlineWithPageNumber.length - 1].pageNumber
					) {
						active = outlineWithPageNumber[outlineWithPageNumber.length - 1];
					}
					payload?.eventBus?.dispatch("updateactiveoutline", active);
				}
			});
		}
	} catch (error) {
		console.log("DEBUG ~ file: documents.sagas.ts ~ line 101 ~ function*readDocument ~ error", error);
	}
}

function* resetCurrentPdfFunctions() {
	const { queries } = parseQuery();

	yield put(
		DocumentsActions.setCurrentOption({
			key: "page",
			value: queries?.pageNr ?? 1,
		}),
	);
	yield put(
		DocumentsActions.setCurrentOption({
			key: "scale",
			value: {
				number: 1,
				type: "",
			},
		}),
	);
	yield put(
		DocumentsActions.setCurrentOption({
			key: "search",
			value: {
				searchValue: "",
				matchWholeWord: false,
				caseSensitive: false,
			},
		}),
	);
	yield put(
		DocumentsActions.setCurrentOption({
			key: "rotate",
			value: 0,
		}),
	);
	yield put(
		DocumentsActions.setCurrentOption({
			key: "cursorMode",
			value: {
				text: "textSelection",
				number: 0,
			},
		}),
	);
	yield put(
		DocumentsActions.setCurrentOption({
			key: "scrollMode",
			value: {
				text: "vertical",
				number: 0,
			},
		}),
	);
}

export default function* networkListeners(): Generator {
	yield all([
		takeLatest(types.SAGAS_GET_PUBLISHED_DOCUMENTS, getPublishedDocuments),
		takeLatest(types.SAGAS_READ_DOCUMENT, readDocument),
		takeLatest(types.SAGAS_RESET_CURRENT_PDF_FUNCTIONS, resetCurrentPdfFunctions),
	]);
}
