// Utilities
import * as R from "ramda";
import { getPlainArrayFromArrayOfObject } from "./array";
// Types
import type { FilterGroup, HeaderGroupItem } from "src/types/dynamic";

/** get selected filter group columns */
function getSelectedColumns(items: FilterGroup[]): string[] {
	return R.pipe(
		R.map<FilterGroup, string[]>((item) => item.columns),
		R.flatten,
		R.uniq,
	)(items);
}

/**
 * get columns of unchecked header filters
 *
 * @param {HeaderGroupItem[]} allHeaderFilterGroups
 * @param {string[]} currentFilterGroups
 * @param {string[]} allFilterGroupColumns
 * @return {string[]}
 */
export function getFilteredOutHeaderGroupColumns(
	allHeaderFilterGroups: HeaderGroupItem[],
	currentFilterGroups: string[],
	allFilterGroupColumns: string[],
): string[] {
	const checkedGroups = R.filter<HeaderGroupItem>(
		(item) => R.includes(item.groupId, currentFilterGroups ?? []),
		allHeaderFilterGroups,
	);

	const selectedFilterGroupColumns = getSelectedColumns(checkedGroups);
	return R.without(selectedFilterGroupColumns, allFilterGroupColumns);
}

export function getDefaultHeaderFilters(headerGroups: HeaderGroupItem[]): string[] {
	return getPlainArrayFromArrayOfObject(headerGroups, "groupId");
}
