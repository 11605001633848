/**
 * @module Sagas/User
 * @desc All User sagas
 */
// Constants
import * as types from "./user.constants";
import * as documentsTypes from "../Documents/documents.constants";
import * as dynamicTypes from "../Dynamic/dynamic.constants";
// Components
import { Notification } from "src/components/Notification";
// Utilities
import { all, takeLatest, call, put, fork, select } from "redux-saga/effects";
import { removeToken, setTokens } from "src/helpers/token";
import { clearAll, read, remove, store } from "src/helpers/localStorage";
import * as R from "ramda";
import i18n from "src/locales";
// Types
import type {
	LoginRequestData,
	SubmitAccessCodeRequestData,
	VerifyAccessCodeResponse,
	SignUpData,
	SignUpFormValues,
} from "src/types/users";
import type { BaseApiResponse } from "src/types/http";
import type { UserState } from "./user.reducer";
import type { StateNetwork } from "../index.reducer";
import type { Action } from "src/types/common";
// Actions
import UserActions from "./user.actions";
import RootActions from "../index.actions";
// Endpoints
import UserService from "src/services/http/endpoints/user";
import AuthenticationService from "src/services/http/endpoints/authentication";

function* getUserInfo() {
	try {
		const result = yield call(() => UserService.getUserInfo());

		yield put(UserActions.setUserInfo(result.data?.payload.user));
	} catch (error) {
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
		}
		console.log("SAGAS: getUserInfo -> error", error);
	}
}

function* postCustomerFeedback(action: Action<any>) {
	try {
		const result = yield call(() => UserService.postCustomerFeedback(action.payload));
		Notification({ message: result.data.message, type: "success" });
		action.callback();
	} catch (error) {
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
		}
		console.log("SAGAS: getUserInfo -> error", error);
	}
}

let forkedBefore = false;
function* forkGetUserInfo() {
	if (!forkedBefore) {
		yield fork(getUserInfo);
		forkedBefore = true;
	}
}

function* login(action: Action<LoginRequestData>) {
	try {
		yield put(UserActions.setLoading(true));
		const result = yield call(() => AuthenticationService.login(action.payload));
		const companyId = R.pathOr("", ["data", "payload", "companyId"], result);
		const email = action.payload?.email;
		yield put(UserActions.setLoginInfo("email", email));
		if (companyId) {
			yield put(UserActions.setShowSignUp(true));
			yield put(UserActions.setLoginInfo("companyId", companyId));
		} else {
			yield put(UserActions.setShowAccessCode(true));
			yield put(UserActions.setSentCode(true));
		}
	} catch (error) {
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(UserActions.setLoading(false));
		}
		console.log("SAGAS: function*login -> error", error);
	} finally {
		yield put(UserActions.setLoading(false));
	}
}

function* submitAccessCode(action: Action<string>) {
	try {
		yield put(UserActions.setLoading(true));
		const userStore = yield select((state: StateNetwork) => state.user);

		const requestData: SubmitAccessCodeRequestData = {
			email: userStore.loginInfo.email,
			code: action?.payload,
		};
		const { data } = yield call(() => AuthenticationService.verifyAccessCode(requestData));

		store("permissions", data.payload.tokenPayload?.permissions);
		store("entities", data.payload.tokenPayload?.entities);
		yield put(UserActions.setAccessTokenData(data?.payload));

		setTokens(data?.payload.accessToken, data?.payload.refreshToken);

		action?.navigate("/dashboard");
		yield put(UserActions.setShowAccessCode(false));
	} catch (error) {
		console.log("DEBUG: function*submitAccessCode -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(UserActions.setLoading(false));
		}
	} finally {
		yield put(UserActions.setLoading(false));
	}
}

function* getRefreshToken() {
	try {
		const currentRefreshToken = read("refreshToken");
		remove("accessToken");
		const { payload }: BaseApiResponse<VerifyAccessCodeResponse> = yield call(() =>
			AuthenticationService.getRefreshToken(currentRefreshToken),
		);

		setTokens(payload.accessToken, payload.refreshToken);
	} catch (error) {
		console.log("DEBUG ~ file: authentication.sagas.ts ~ line 54 ~ function*getRefreshToken ~ error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
		}
	}
}

function* logout(action) {
	try {
		yield put(UserActions.setShowAccessCode(false));
		yield put(UserActions.setSentCode(false));

		const result = yield call(() => AuthenticationService.logout());
		if (result.data?.message) {
			Notification({
				message: result.data?.message,
				type: "success",
				configs: {
					duration: 4,
				},
			});
		}
	} catch (error) {
		console.log("DEBUG: function*logout -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
		}
	} finally {
		removeToken();
		remove("refreshToken");
		yield put(UserActions.setPermissions(null));
		yield put(RootActions.resetFactory());
		clearAll();
		if (action.navigate) {
			action?.navigate("/authentication/login");
		} else {
			window.location.href = "/authentication/login";
		}
	}
}

function* signUp(action: Action<SignUpFormValues>) {
	try {
		yield put(UserActions.setLoading(true));
		const { firstName, lastName } = action.payload;
		const userStore: UserState = yield select((state: StateNetwork) => state.user);

		const data: SignUpData = {
			companyId: userStore.loginInfo.companyId,
			email: userStore.loginInfo.email,
			firstName,
			lastName,
		};

		yield call(() => AuthenticationService.signUp(data));

		yield put(UserActions.setShowAccessCode(true));
		yield put(UserActions.setSentCode(true));
	} catch (error) {
		console.log("DEBUG: function*signUp -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
			yield put(UserActions.setLoading(false));
		}
	} finally {
		yield put(UserActions.setLoading(false));
	}
}

export default function* networkListeners() {
	yield all([
		takeLatest(types.SAGAS_SET_USER_INFO, getUserInfo),
		takeLatest(dynamicTypes.BODY, forkGetUserInfo),
		takeLatest(documentsTypes.SAGAS_GET_PUBLISHED_DOCUMENTS, forkGetUserInfo),
		takeLatest(types.SAGAS_LOGIN, login),
		takeLatest(types.SAGAS_LOGOUT, logout),
		takeLatest(types.SAGAS_SUBMIT_ACCESS_CODE, submitAccessCode),
		takeLatest(types.SAGAS_GET_REFRESH_TOKEN, getRefreshToken),
		takeLatest(types.SAGAS_SIGN_UP, signUp),
		takeLatest(types.POST_CUSTOMER_FEEDBACK, postCustomerFeedback),
	]);
}
