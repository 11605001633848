import React from "react";
// Libraries
import * as Sentry from "@sentry/react";
// Common components
import EmptyState from "src/components/EmptyState";

export const ErrorBoundary: React.FC = (props) => {
	return (
		<Sentry.ErrorBoundary fallback={<EmptyState color={"danger"} description={"There is an error! Try again."} />}>
			{props.children}
		</Sentry.ErrorBoundary>
	);
};
