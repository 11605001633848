import { createStore, applyMiddleware, compose } from "redux";
// Reducers
import rootReducer from "./index.reducer";
// Sagas
import sagas from "./index.sagas";
// List of middleware
import middlewares, { sagaMiddleware } from "./index.middleware";
// Actions
import UserActions from "./User/user.actions";
// Utilities
import { isDev } from "src/helpers";
import { persistStore } from "redux-persist";

const composeEnhancers =
	isDev() && typeof window === "object" && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== "undefined"
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				trace: true,
				traceLimit: 25,
			})
		: compose;
// Create store and also apply all middleware such as Redux-devtools and redux-saga and redux-logger in this project
// @ts-ignore
export const appStore = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middlewares)));
// Run redux-saga middleware and apply all forked sagas that we have created
sagaMiddleware.run(sagas);

// Logout user whenever got `401` http status from the server response.
window.addEventListener("logout", () => {
	appStore.dispatch(UserActions.logout());
});
// get refresh token whenever got `401` http status from the server response.
window.addEventListener("getRefreshToken", () => {
	appStore.dispatch(UserActions.getRefreshToken());
});

// @ts-ignore
export const persistor = persistStore(appStore);
