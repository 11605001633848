/**
 * @module Reducer/User
 * @desc All User reducers
 */
import * as React from "react";
import * as types from "./user.constants";
// Utilities
import * as R from "ramda";
// Types
import type { LoginInfo, UserInfo, VerifyAccessCodeResponse } from "src/types/users";
import type { Action } from "src/types/common";

export interface UserState {
	userInfo: Partial<UserInfo>;
	/** state of sentCode */
	sentCode: boolean;
	/** whenter show access code form or not */
	showAccessCode: boolean;
	/** whether show sign up form or not */
	showSignUp: boolean;
	/** access token data that got after login */
	accessTokenData: Partial<VerifyAccessCodeResponse>;
	/** loading state */
	loading: boolean;
	/** login information such as companyId and email */
	loginInfo: LoginInfo;
	/** user permissions read from token */
	permissions: string[] | null;
	entities: any | null;
}

const initialState: UserState = {
	userInfo: {},
	sentCode: false,
	showAccessCode: false,
	showSignUp: false,
	accessTokenData: {},
	loading: false,
	loginInfo: {
		email: "",
		companyId: "",
	},
	permissions: null,
	entities: null,
};

const reducer: React.Reducer<UserState, Action> = (state = initialState, action) => {
	const update = R.mergeRight<UserState>(state);
	switch (action.type) {
		case types.SET_USER_INFO:
			return update({
				userInfo: action.payload,
			});
		case types.SET_SENT_CODE:
			return update({
				sentCode: action.payload,
			});
		case types.SET_SHOW_ACCESS_CODE:
			return update({
				showAccessCode: action.payload,
			});
		case types.SET_ACCESS_TOKEN_DATA:
			return update({
				accessTokenData: action.payload,
			});
		case types.SET_SHOW_SIGN_UP:
			return update({
				showSignUp: action.payload,
			});
		case types.SET_LOGIN_INFO:
			return update({
				loginInfo: {
					...state.loginInfo,
					[action.payload.key]: action.payload.value,
				},
			});
		case types.SET_USER_PERMISSIONS:
			return update({
				permissions: action.payload,
			});
		case types.SET_USER_ENTITIES:
			return update({
				entities: action.payload,
			});
		case types.SET_LOADING:
			return update({
				loading: action.payload,
			});
		default:
			return state;
	}
};

export default reducer;
