/**
 * @module Constants/Notifications
 * @desc All notifications constant variables
 */

import { createReduxConstant } from "src/helpers";

const prefix = createReduxConstant("NOTIFICATIONS");

/**
 * @module NOTIFICATIONS
 * @desc get and set notifications
 */
export const SET_NOTIFICATIONS = prefix("SET_NOTIFICATIONS");
export const SAGAS_SET_NOTIFICATIONS = prefix("SAGA_SET_NOTIFICATIONS");

/**
 * @module NOTIFICATIONS
 * @desc get and set notifications unread count
 */
export const SET_NOTIFICATIONS_UNREAD_COUNT = prefix("SET_NOTIFICATIONS_UNREAD_COUNT");
export const SAGAS_SET_NOTIFICATIONS_UNREAD_COUNT = prefix("SAGA_SET_NOTIFICATIONS_UNREAD_COUNT");

/**
 * @module NOTIFICATIONS
 * @desc set loading state
 */
export const SET_LOADING = prefix("SET_LOADING");
