// Utilities
import * as R from "ramda";

export function getAccessibleVersions(
	permissions: string[],
	type: string,
): Record<"versionType" | "versionNumber", string>[] {
	const permissionsList = R.filter<string>(
		(item) =>
			item.startsWith(type) &&
			!item.endsWith("document_view") &&
			!item.endsWith("revision_history") &&
			!item.endsWith("export"),
		permissions ?? [],
	);

	return R.map((item) => {
		// in bandCombo the bellow splitItem has the length of 4 instead of 3
		const splitItem = item.split("_");

		return {
			versionType: splitItem[1]?.toLowerCase(),
			versionNumber: R.last(splitItem),
		};
	}, permissionsList);
}
