/**
 * @module Sagas/Notifications
 * @desc All notifications sagas
 */
import { all, takeLatest, put, call, fork } from "redux-saga/effects";
// Utilities
import * as R from "ramda";
import i18n from "src/locales";
// Services
import NotificationsService from "src/services/http/endpoints/notifications";
// Actions
import NotificationsActions from "./notifications.actions";
// Constants
import * as types from "./notifications.constants";
import { Notification } from "src/components/Notification";

function* getNotifications() {
	try {
		yield put(NotificationsActions.setLoading(true));
		const result = yield call(() =>
			NotificationsService.getNotifications({
				page: 1,
				size: 30,
			}),
		);

		yield put(NotificationsActions.setNotifications(result.data?.payload.notifications));
		yield put(NotificationsActions.setNotificationsUnreadCount(null, { sagas: true }));
	} catch (error) {
		console.log("DEBUG: function*getNotifications -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
		}
	} finally {
		yield put(NotificationsActions.setLoading(false));
	}
}

function* getNotificationsUnreadCount() {
	try {
		const result = yield call(() => NotificationsService.getNotificationsUnreadCount());

		yield put(NotificationsActions.setNotificationsUnreadCount(result.data?.payload.count));
	} catch (error) {
		console.log("DEBUG: function*getNotificationsUnreadCount -> error", error);
		if (R.includes(error?.message, ["timeout", "CORS"])) {
			Notification({ message: i18n.t("common.serverError"), type: "error" });
		}
	}
}

let forkedBefore = false;
function* forkGetNotification() {
	if (!forkedBefore) {
		yield fork(getNotificationsUnreadCount);
		forkedBefore = true;
	}
}

export default function* networkListeners() {
	yield all([
		takeLatest(types.SAGAS_SET_NOTIFICATIONS, getNotifications),
		takeLatest(types.SAGAS_SET_NOTIFICATIONS_UNREAD_COUNT, getNotificationsUnreadCount),
	]);
}
