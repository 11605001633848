import { isProduction } from "src/helpers";

export const PAGE_SIZE = isProduction() ? 500 : 100;
export const CACHE_BLOCK_SIZE = isProduction() ? 500 : 100;

export const FILTERS = {
	equals: "Equals",
	notEqual: "Not equal",
	lessThan: "Less than",
	lessThanOrEqual: "Less than or equal",
	greaterThan: "Greater than",
	greaterThanOrEqual: "Greater Than Or Equal",
	inRange: "In Range",
	blank: "Blank",
	notBlank: "Not blank",
	chooseOne: "Empty",
	contains: "Contains",
	notContains: "Not contains",
	startsWith: "Starts With",
	endsWith: "Ends With",
	// empty
	hasEmpty: "Has empty",
	completeEmpty: "Completely empty",
	notEmpty: "Not empty",
};
