import * as React from "react";
// Libraries component
import { Navigate } from "react-router";
// Utilities
import { readToken } from "src/helpers/token";

export const PrivateRoute = (props) => {
	const { children } = props;

	if (readToken()) {
		return children;
	}

	return <Navigate to="/authentication/login" />;
};
