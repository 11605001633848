// Utilities
import * as R from "ramda";
import { getTsNumberToDocumentTypeMapping } from "./dataVersion";
// Types
import type {
	Document,
	GetDefaultDataVersions,
	GetTsNumbersWithDedicatedVersions,
	GroupWithDedicatedDataTypes,
} from "src/types/documents";

/**
 * remove duplicate ts numbers
 *
 * @param {Document[]} documents - list of documents
 * @return {string[]}
 */
function getUniqueTsNumbers(documents: Document[]): string[] {
	return R.pipe(
		R.map<Document, string>((item) => item.tsNumber),
		R.uniq,
	)(documents);
}

/**
 * returns tsNumbers and versions and mapping of them
 *
 * @param {Document[]} documents - list of documents
 * @return {GetTsNumbersWithDedicatedVersions}
 */
export function getTsNumbersVersionsDataFromDocument(documents: Document[]): GetTsNumbersWithDedicatedVersions {
	const tsNumberToDocumentTypeMapping = getTsNumberToDocumentTypeMapping(documents);

	const uniqueTsNumbers = getUniqueTsNumbers(documents);

	const groups = documents.reduce(function (r, a) {
		r[a.group] = r[a.group] || [];
		r[a.group].push(a.dataType);
		return r;
	}, Object.create(null));

	const dataTypes = documents.reduce(function (r, a) {
		r[a.dataType] = r[a.dataType] || [];
		r[a.dataType].push(a);
		return r;
	}, Object.create(null));

	const finalData = [] as GroupWithDedicatedDataTypes[];
	Object.keys(groups).forEach((item) => {
		const dt = [...new Set(groups[item])].map((g: string) => {
			return { dataType: g, versions: dataTypes[g].map((d) => d.version).reverse() };
		});
		finalData.push({
			group: item,
			dataTypes: dt,
		});
	});
	return {
		tsNumberToDocumentTypeMapping,
		uniqueTsNumbers,
		groupWithDedicatedDataTypes: finalData,
	};
}

export function getDefaultDataVersions(groups: GroupWithDedicatedDataTypes[]): GetDefaultDataVersions {
	const defaultGroup = groups[0].group;
	const defaultDataType = groups[0].dataTypes[0].dataType;
	const defaultVersion = groups[0].dataTypes[0].versions[0];

	return {
		defaultGroup,
		defaultDataType,
		defaultVersion,
	};
}
