// Utilities
import * as R from "ramda";
// Helpers
import { getParamsFromExpression } from "src/helpers/advancedFilter";
// Types
import type { Dictionary, KeyName, KeyValue, StringNumber } from "src/types/common";
import type { AdvancedFilterModel, DynamicTableParams, PagesKey } from "src/types/dynamic";
import { getInstance } from "@ag-grid-community/react";

/**
 * Generate random 16 radix via `Math.random` and convert a `string` then take the third character
 *
 * @returns {string}
 */
export function randomString(): string {
	return Math.random().toString(16).slice(2);
}

export const createReduxConstant = (prefix: string) => (keyword: string) => prefix + "/" + keyword;

// production mode
export const isProduction = () => import.meta.env.VITE_APP_ENV === "production";
// Staging mode
export const isStaging = () => import.meta.env.VITE_APP_ENV === "staging";
// Development
export const isDevelopment = () => import.meta.env.VITE_APP_ENV === "development";
// Staging or Development
export const isDev = () => isStaging() || isDevelopment();

/**
 * Using Base url in http service
 * NOTE:
 * 		When we are in development or staging mode, there is no need to call the API url directly and we have to proxy the API server in our Application by using http-proxy-middleware package.
 *
 * @return {string}
 */
export const getBaseURL = () => {
	if (isProduction()) {
		return import.meta.env.VITE_APP_API_URI;
	} else if (isDevelopment() || isStaging()) {
		return import.meta.env.VITE_APP_PROXY_URL; // when we are in the staging or development mode
	}
};

/**
 * Detect if user's device was ios
 *
 * @return {boolean}
 */
export function isIOS(): boolean {
	return (
		["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
			navigator.platform,
		) ||
		// iPad on iOS 13 detection
		(navigator.userAgent.includes("Mac") && "ontouchend" in document)
	);
}

/**
 * Detect if user's IOS version was 14
 *
 * @return {boolean}
 */
export const isIOS14 = (): boolean => /OS 14/.test(window.navigator.userAgent);

/**
 * window.navigator.standalone is primarily used by Webkit browsers to indicate the app is in fullscreen (or standalone) mode.
 * Plenty of devices (like phones running Android), support this property, but don't have the option to 'Add to Homescreen' like iOS devices do, so you need to check both.
 */
export const isStandalone = (): boolean => {
	return window.navigator.standalone;
};

/**
 * The display-mode CSS media feature can be used to test the display mode of an application.
 * You can use it to provide a consistent user experience between launching a site from a URL and launching it from a desktop icon.
 *
 * This feature corresponds to the Web app manifest's display member.
 * Both apply to the top-level browsing context and any child browsing contexts.
 * The feature query applies regardless of whether a web app manifest is present.
 */
export const isStandaloneDisplayMode = (): boolean => {
	return window.matchMedia("(display-mode: standalone)").matches;
};

/**
 * To track how users launch your PWA, use matchMedia() to test the display-mode media query.
 * Safari on iOS doesn't support this yet, so you must check navigator.standalone, it returns a boolean indicating whether the browser is running in standalone mode.
 */
export const displayModeLaunch = () => {
	let displayMode = "browser-tab";
	if (isStandalone()) {
		displayMode = "standalone-ios";
	}
	if (isStandaloneDisplayMode()) {
		displayMode = "standalone";
	}

	return displayMode;
};

export function downloadFile(data: Blob, name: string, type: string): void {
	const downloadUrl = window.URL.createObjectURL(new Blob([data], { type }));
	const element = document.createElement("a");
	element.href = downloadUrl;
	element.setAttribute("download", name);
	document.body.appendChild(element);
	element.click();
	element.remove();
}

export function getDynamicGroup(pageKey: PagesKey) {
	return pageKey === "bandsCombo" ? "band-combo" : pageKey;
}

/**
 * Convert key value object to plain object
 *
 * @param {KeyValue[]} selectedVersionsArray
 * @return {Dictionary<string>}
 */
export function getNormalizedDataVersions(selectedVersionsArray: KeyValue[]): Dictionary<string> {
	const normalizedVersions: Dictionary<string> = {};

	R.forEach<KeyValue>((item) => {
		normalizedVersions[item.key] = item.value;
	}, selectedVersionsArray);

	return normalizedVersions;
}

export function normalizeEntriesModel<P1 extends string, P2 extends string>(entries: Array<[P1, P2]>): Record<P1, P2> {
	const result: Record<P1, P2> = {} as Record<P1, P2>;

	R.forEach<[P1, P2]>((item) => {
		result[item[0]] = item[1];
	}, entries);

	return result;
}

export function normalizeParams(dataParams: Dictionary<string>): Partial<DynamicTableParams> {
	// if there is an active body filter
	if (!R.isEmpty(dataParams?.filterModel)) {
		const result = Object.entries(dataParams.filterModel);
		const normalizedFilterModel = R.map<[string, any], any>((item) => {
			if (item[1]?.filter === null) {
				return null;
			} else {
				const paramFromExpression = getParamsFromExpression(item[1] as AdvancedFilterModel);
				item[1] = R.includes(item[1]?.filterType, ["text", "number"])
					? (paramFromExpression as Dictionary<string | number | Dictionary<StringNumber>[]>)
					: { values: item[1].filter, type: item[1].type };
				return item;
			}
		}, result).filter(Boolean);

		return {
			...R.omit(["filterModel"], dataParams),
			...R.fromPairs(normalizedFilterModel),
		};
	} else {
		return R.omit(["filterModel"], dataParams);
	}
}

export async function changeSelectBoxValues(selectBoxValues: Record<string, KeyName[]>, entity: string) {
	const pairedSelectBoxValues = R.toPairs(selectBoxValues);
	const gridApi = window.dynamicGridApi;
	pairedSelectBoxValues.forEach((item) => {
		const [colName, values] = item;
		gridApi.getColumnFilterInstance(colName).then((filterInstance) => {
			getInstance(filterInstance, (comp) => {
				if (comp != null) {
					//@ts-ignore
					comp.changeItems(values);
				}
			});
		});
	});
}
