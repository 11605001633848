import { combineReducers } from "redux";
// Reducers
import notifications, { NotificationsState } from "./Notifications/notifications.reducer";
import user, { UserState } from "./User/user.reducer";
import common, { CommonState } from "./Common/common.reducer";
import documents, { DocumentsState } from "./Documents/documents.reducer";
import collectors, { CollectorsState } from "./Collectors/collectors.reducer";
// Utilities
import { persistReducer } from "redux-persist";
import { persistConfig } from "src/store/index.utils";
import { ApplicationConfig } from "src/app.config";
import dynamic, { DynamicState } from "./Dynamic/dynamic.reducer";

export interface StateNetwork {
	dynamic: DynamicState;
	notifications: NotificationsState;
	user: UserState;
	common: CommonState;
	documents: DocumentsState;
	collectors: CollectorsState;
}

function persistableReducer(key, reducer, blacklist: string[] = []) {
	return ApplicationConfig.persist.enabled && ApplicationConfig.persist[key]
		? persistReducer(persistConfig(key, blacklist), reducer)
		: reducer;
}

// Combine all reducers together to control by redux reducers observer.
const rootReducer = combineReducers({
	dynamic: persistableReducer("dynamic", dynamic, [
		"gridApi",
		"chart",
		"loading",
		"snapshot",
		"rowCount",
		"chartVisible",
	]),
	notifications: persistableReducer("notifications", notifications),
	user: persistableReducer("user", user, ["loading", "showSignUp", "showAccessCode", "sentCode"]),
	common: persistableReducer("common", common),
	collectors: persistableReducer("collectors", collectors),
	documents: persistableReducer("documents", documents, [
		"loading",
		"publishedDocuments",
		"dataVersionDataList",
		"selectedGroup",
		"selectedDataType",
		"selectedVersion",
		"tsNumberList",
		"versionList",
		"dataVersionModalOpened",
		"tsNumberToDocumentTypeMapping",
		"selectedDocument",
		"outline",
		"tocIsOpened",
		"tocIsLocked",
	]),
});
export default rootReducer;
