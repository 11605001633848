/**
 * @module Constants/common
 * @desc All common constant variables
 */

import { createReduxConstant } from "src/helpers";

const prefix = createReduxConstant("COMMON");

/**
 * @module COMMON
 * @desc set tab group name
 */
export const SET_TAB_GROUP_NAME = prefix("SET_TAB_GROUP_NAME");

/**
 * @module COMMON
 * @desc set tab name
 */
export const SET_TAB_NAME = prefix("SET_TAB_NAME");

/**
 * @module LOADING
 * @desc set loading state
 */
export const SET_LOADING = prefix("SET_LOADING");

/**
 * @module OPEN_INTRO
 * @desc set openIntro state
 */
export const SET_OPEN_INTRO = prefix("SET_OPEN_INTRO");
