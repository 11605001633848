/**
 * @module Reducer/Dynamic
 * @desc All Bands reducers
 */
import * as types from "./dynamic.constants";
// Utilities
import * as R from "ramda";
// Helpers
import { getNormalizedDataVersions } from "src/helpers";
// Types
import type { Action, Dictionary, KeyValue } from "src/types/common";
import type { BandComboGraphicsResponse, GraphicsTableResponse, Intermodulation } from "src/types/bandComboGraphics";
import type {
	DynamicBody,
	DynamicHeader,
	Filters,
	RowCountResponse,
	DynamicTableParams,
	PageStatus,
	TableOptions,
	RevisionInfo,
	RevisionRowCount,
	EditedField,
	DynamicLoadingModel,
	DataRateParams,
	BandsGraphicsChart,
	BandsGraphics,
	BandsGraphicsParams,
	VisualizationResponse,
	VisualizationResponseData,
	TableNote,
} from "src/types/dynamic";
import type { Reducer } from "react";
// Constants
import { DataRateTableResponse } from "src/types/bandComboDataRate";
import { DynamicGraphicsParams } from "src/types/dynamic";

export interface BaseDynamicState {
	/** header - got from api */
	header: Partial<DynamicHeader>;
	/** originHeader - got from api - use it for group by */
	originHeader: Partial<DynamicHeader>;
	/** list of data versions */
	dataVersions: Partial<Filters>;
	/** open state of dataversion modal */
	dataVersionModalOpened: boolean;
	/** table body */
	body: Partial<DynamicBody>[];
	/** Row Count */
	rowCount: Partial<RowCountResponse>;
	/** Loaded Count */
	loadedCount: number;
	/** whether revision is active */
	isRevision: boolean;
	/** whether group by is active */
	isGroupBy: boolean;
	/** whether group by header is set */
	isGroupByHeadersSet: boolean;
	/** whether exit group by is active */
	isExitGroupBy: boolean;
	/** currently selected data versions */
	selectedDataVersion: KeyValue[];
	normalizedDataVersion: Dictionary<string>;
	chart: BandsGraphicsChart;
	visualization: VisualizationResponse;
	/** bands parameters - consist of data versions, header filter and body filter */
	tableParams: DynamicTableParams;
	/** current page number */
	page: number;
	/** current page options - consist of data version, header filter and body filter */
	currentTableOptions: Partial<TableOptions>;
	/** loading state */
	loading: DynamicLoadingModel;
	/** User has clicked on Rest search or Reset all button */
	clearedSearch: boolean;
	/** whether graphics drawer is open or not */
	chartVisible: boolean;
	/** whether graphics drawer is open or not */
	bandsChartVisible: boolean;
	/** whether Visualisation drawer is open or not */
	visualisationDrawerVisible: boolean;
	/** revision information */
	revisionInfo: RevisionInfo;
	/** row count of revision mode */
	revisionRowCount: RevisionRowCount;
	/** whether it is edit mode */
	isEditMode?: boolean;
	/** whether table cells are editable */
	cellsAreEditable?: boolean;
	/** edited fields values */
	editedFieldsValues?: EditedField[];
	/** whether it's add mode or not */
	isAdd?: boolean;
	/** status of add */
	addStatus?: "success" | "error" | null;
	/** whether the table cells are clickable or not */
	isTableCellsClickable: boolean;
	/** data for band combo graphics table */
	graphicsTable: GraphicsTableResponse;
	/** data for band combo graphics */
	graphics: BandComboGraphicsResponse;
	/** data for band combo custom graphics calculation */
	customGraphics: BandComboGraphicsResponse;
	/** graphics params - uplink and downlink */
	graphicsParams: DynamicGraphicsParams;
	/** dataRate params - uplinkBands and downlinkBands and version */
	dataRateParams: DataRateParams;
	/** whether dataRate drawer is open or not */
	dataRateVisible: boolean;
	/** data for band combo dataRate table */
	dataRateTable: DataRateTableResponse;
	/** whether the table cells are clickable or not */
	bandsGraphicsParams: BandsGraphics;
	selectedVisualizationDataVersion: KeyValue[];
	selectedVisualizationCompareDataVersion: KeyValue[];
	/** data for table notes */
	notes: any;
	/** selected note */
	selectedNote: TableNote | null;
	/** group by note */
	isGroupByVisible: boolean;
	groupByData: any;
	previewGroupByData: any;
	/** profiles */
	profiles: {
		entity: Array<any>;
		visualization: Array<any>;
	};
	entity: string;
}

export interface DynamicState extends BaseDynamicState {
	/** to handle whether get new data or not */
	snapshot: Omit<PageStatus, "graphicsTable" | "graphics">;
}

const initialState: DynamicState = {
	header: {},
	originHeader: {},
	body: [],
	rowCount: {},
	loadedCount: 0,
	dataVersions: {},
	dataVersionModalOpened: false,
	isGroupBy: false,
	isGroupByHeadersSet: false,
	isRevision: false,
	isExitGroupBy: false,
	selectedDataVersion: [],
	normalizedDataVersion: {},
	chart: {
		bands: [],
		higlightRanges: {
			displayName: "",
			uplink: {
				start: 0,
				end: 0,
			},
			downlink: {
				start: 0,
				end: 0,
			},
		},
	},
	tableParams: {},
	page: 1,
	currentTableOptions: {},
	clearedSearch: false,
	chartVisible: false,
	bandsChartVisible: false,
	visualisationDrawerVisible: false,
	loading: {
		header: true,
		rowCount: true,
		body: true,
		dataVersion: true,
		filter: false,
		chart: false,
		export: false,
		graphicsTable: false,
		dataRateTable: false,
		edit: false,
		table: false,
		visualization: false,
	},
	snapshot: {
		body: {},
		chart: {},
		header: false,
		rowCount: false,
		revisionRowCount: false,
	},
	revisionInfo: {} as RevisionInfo,
	revisionRowCount: {} as RevisionRowCount,
	isEditMode: false,
	cellsAreEditable: false,
	editedFieldsValues: [],
	isAdd: false,
	addStatus: null,
	isTableCellsClickable: false,
	graphicsTable: {
		headers: [],
		body: [],
		defaultMaxOrder: 0,
	},
	graphics: {
		bands: [],
		intermodulation: {} as Intermodulation,
	},
	customGraphics: {
		bands: [],
		intermodulation: {} as Intermodulation,
	},
	graphicsParams: {} as DynamicGraphicsParams,
	dataRateParams: {} as DataRateParams,
	dataRateVisible: false,
	dataRateTable: {
		headers: [],
		body: {
			downLink: [],
			upLink: [],
		},
		aggDataRate: {
			upload: null,
			download: null,
		},
	},
	bandsGraphicsParams: {} as BandsGraphicsParams,
	visualization: { data: [] as VisualizationResponseData[], metaData: {} },
	selectedVisualizationDataVersion: [],
	selectedVisualizationCompareDataVersion: [],
	notes: {},
	groupByData: undefined,
	previewGroupByData: undefined,
	isGroupByVisible: false,
	selectedNote: null,
	profiles: {
		entity: [],
		visualization: [],
	},
	entity: "",
};

const reducer: Reducer<DynamicState, Action> = (state = initialState, action) => {
	const update = (newState: Partial<DynamicState>) => R.mergeRight<DynamicState>(state)(newState);
	switch (action.type) {
		case types.HEADER:
			return update({
				header: action.payload.data,
				snapshot: R.mergeRight(state.snapshot)({
					header: action?.payload?.meta?.memoize || true,
				}),
			});
		case types.ORIGIN_HEADER:
			return update({
				originHeader: action.payload.data,
			});
		case types.BODY:
			const body = action.payload.data?.data || [];
			return update({
				body,
			});
		case types.NOTES:
			const notes = action.payload?.data || [];
			return update({
				notes,
			});
		case types.ROW_COUNT:
			return update({
				rowCount: action.payload.data,
				snapshot: R.mergeRight(state.snapshot)({
					rowCount: action?.payload?.meta?.memoize || true,
				}),
			});
		case types.SET_LOADED_COUNT:
			return update({
				loadedCount: action.payload,
			});
		case types.SET_TABLE_PARAMS:
			return update({
				tableParams: action.payload,
			});
		case types.CLEARED_SEARCH:
			return update({
				clearedSearch: action.payload,
			});
		case types.NEW_LOADING:
			const loading = action.payload.data[0];
			return update({
				loading: {
					...state.loading,
					[loading.type]: loading.status,
				},
			});
		case types.DATA_VERSION_FILTER_LIST:
			return update({
				dataVersions: action.payload.data,
			});
		case types.SET_IS_REVISION:
			return update({
				isRevision: action.payload,
			});
		case types.SET_IS_GROUP_BY:
			return update({
				isGroupBy: action.payload,
			});
		case types.SET_IS_GROUP_BY_HEADERS_SET:
			return update({
				isGroupByHeadersSet: action.payload,
			});
		case types.SET_IS_EXIT_GROUP_BY:
			return update({
				isExitGroupBy: action.payload,
			});
		case types.SET_REVISION_INFO:
			return update({
				revisionInfo: action.payload,
			});
		case types.SET_GROUP_BY_DATA:
			return update({
				groupByData: action.payload,
			});
		case types.SET_PREVIEW_GROUP_BY_DATA:
			return update({
				previewGroupByData: action.payload,
			});
		case types.SET_REVISION_ROW_COUNT:
			return update({
				revisionRowCount: action.payload.data,
				snapshot: R.mergeRight(state.snapshot)({
					revisionRowCount: action?.payload?.meta?.memoize || true,
				}),
			});
		case types.SELECTED_DATA_VERSION:
			return update({
				selectedDataVersion: action.payload,
				normalizedDataVersion: getNormalizedDataVersions(action.payload),
			});
		case types.SET_DATAVERSION_MODAL_OPEN:
			return update({
				dataVersionModalOpened: action.payload,
			});
		case types.SELECTED_VISUALIZATION_COMPARE_DATA_VERSION:
			return update({
				selectedVisualizationCompareDataVersion: action.payload,
			});
		case types.SELECTED_VISUALIZATION_DATA_VERSION:
			return update({
				selectedVisualizationDataVersion: action.payload,
			});
		case types.SET_CHART:
			return update({
				chart: action.payload,
			});
		case types.SET_PAGE:
			return update({
				page: action.payload,
			});
		case types.CURRENT_TABLE_OPTIONS:
			const { data } = action.payload;
			return update({
				currentTableOptions: R.mergeRight(state.currentTableOptions)(
					data.key
						? {
								[data.key]: data.value,
							}
						: data,
				),
			});
		case types.SET_EDIT_MODE:
			return update({
				isEditMode: action.payload,
			});
		case types.SET_CELLS_EDITABLE:
			return update({
				cellsAreEditable: action.payload.data,
			});
		case types.SET_EDITED_FIELDS:
			return update({
				editedFieldsValues: action.payload,
			});
		case types.SET_IS_CHART_VISIBLE:
			return update({
				chartVisible: action.payload,
			});
		case types.SET_IS_BANDS_CHART_VISIBLE:
			return update({
				bandsChartVisible: action.payload,
			});
		case types.SET_BANDS_GRAPHICS_PARAMS:
			return update({
				bandsGraphicsParams: action.payload,
			});

		case types.SET_IS_ADD:
			return update({
				isAdd: action.payload,
			});

		case types.SET_ADD_STATUS:
			return update({
				addStatus: action.payload,
			});

		case types.MAKE_TABLE_CLICKABLE:
			return update({
				isTableCellsClickable: action.payload,
			});
		case types.MEMOIZE_SNAPSHOT:
			return update({
				snapshot: R.mergeRight(state.snapshot)(action.payload),
			});
		case types.HANDLE_GRAPHICS_TABLE:
			return update({
				graphicsTable: action.payload.data,
			});
		case types.HANDLE_GRAPHICS:
			return update({
				graphics: action.payload.data,
			});
		case types.SET_GRAPHICS_PARAMS:
			return update({
				graphicsParams: action.payload,
			});
		case types.SET_DATARATE_PARAMS:
			return update({
				dataRateParams: action.payload,
			});
		case types.HANDLE_DATARATE_TABLE:
			return update({
				dataRateTable: action.payload.data,
			});
		case types.SET_IS_DATARATE_VISIBLE:
			return update({
				dataRateVisible: action.payload,
			});
		case types.RESET_FACTORY:
			return update(initialState);
		case types.SET_VISUALIZATION:
			return update({
				visualization: action.payload,
			});
		case types.SET_IS_VISUALIZATION_VISIBLE:
			return update({
				visualisationDrawerVisible: action.payload,
			});
		case types.SET_NOTE:
			return update({
				selectedNote: action.payload.data,
			});
		case types.SET_GROUP_BY_VISIBILITY:
			return update({
				isGroupByVisible: action.payload,
			});
		case types.SET_PROFILES:
			return update({
				profiles: action.payload.data,
			});
		case types.SET_ENTITY:
			return update({
				entity: action.payload,
			});
		default:
			return state;
	}
};

export default reducer;
