// Types
import type { AdvancedFilterTypesKeyValue } from "src/types/dynamic";

export const advancedFilterTypesMapping: AdvancedFilterTypesKeyValue = {
	equals: "eq",
	notEqual: "neq",
	lessThan: "lt",
	lessThanOrEqual: "lte",
	greaterThan: "gt",
	greaterThanOrEqual: "gte",
	// isRange is not active right now
	inRange: "inRange",
	contains: "contains",
	notContains: "notContains",
	startsWith: "startsWith",
	endsWith: "endsWith",
	SELECT_BOX: "SELECT_BOX",
	SELECT_BOX_COMBINATION: "SELECT_BOX_COMBINATION",
	// empty
	hasEmpty: "hasEmpty",
	completeEmpty: "completeEmpty",
	notEmpty: "notEmpty",
};
