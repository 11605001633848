// Utilities
import { getDynamicGroup } from "src/helpers";
import HttpService from "../base";
// Types
import type { BaseResponse } from "../base";
import type { Dictionary } from "src/types/common";
import type {
	DynamicBody,
	DynamicHeader,
	ChartType,
	BandType,
	RowCountResponse,
	RevisionRowCount,
	BandsGraphics,
} from "src/types/dynamic";
import type { BandComboGraphicsResponse, GraphicsTableResponse } from "src/types/bandComboGraphics";
import {
	DeleteProfileRequest,
	DynamicCustomGraphic,
	DynamicGraphicsParams,
	GetProfileRequest,
	SaveProfileRequest,
} from "src/types/dynamic";
import * as R from "ramda";

class DynamicProvider extends HttpService {
	constructor() {
		super({ group: "data" });
	}

	/**
	 * Fetch Headers list
	 *
	 * @async
	 * @since 1.0.0
	 */
	getHeaders(entity: string): BaseResponse<DynamicHeader> {
		return this.httpService.get(`/dynamic-data/header/${entity}`, {
			params: {},
		});
	}

	/**
	 * edit band/band combo
	 *
	 * @async
	 * @since 1.0.93
	 */
	editRow(type: BandType, data: any): BaseResponse {
		return this.httpService.put(`${getDynamicGroup(type)}/admin`, data);
	}

	/**
	 * add band/band combo
	 *
	 * @async
	 * @since 1.0.93
	 */
	addRow(type: BandType, data: any): BaseResponse {
		return this.httpService.post(`${getDynamicGroup(type)}/admin`, data);
	}

	/**
	 * Fetch Body list
	 *
	 * @async
	 * @since 1.0.0
	 */
	getDynamicBody(entity: string, params?: Dictionary, isAdmin?: boolean): BaseResponse<DynamicBody> {
		return this.httpService.get(`/dynamic-data/${entity}`, {
			params,
		});
	}

	/**
	 * Get Row count data
	 *
	 * @async
	 * @since 1.0.93
	 */
	getRowCount(entity: string, params?: Dictionary): BaseResponse<RowCountResponse> | undefined {
		return this.httpService.get(`/dynamic-data/row-count/${entity}`, {
			params,
		});
	}

	/**
	 * Fetch Bands Combination Revision Body list
	 *
	 * @async
	 * @since 1.0.60
	 */
	getRevisionBody(entity: string, params?: Dictionary): BaseResponse<DynamicBody> {
		return this.httpService.get(`revision-history/dynamic-data/${entity}`, {
			params,
		});
	}

	/**
	 * Get Row count data
	 *
	 * @async
	 * @since 1.0.60
	 */
	getRevisionRowCount(entity: string, params?: Dictionary): BaseResponse<RevisionRowCount> | undefined {
		return this.httpService.get(`revision-history/dynamic-data/${entity}/count`, {
			params,
		});
	}

	/**
	 * Get Band chart data
	 *
	 * @async
	 * @since 1.0.0
	 */
	getChart(params: Dictionary<string>): BaseResponse<ChartType> {
		return this.httpService.get("bands/graphics", {
			params,
		});
	}

	/**
	 * Exporting the Band and bandsCombo data
	 *
	 * @async
	 * @since 1.0.2
	 */
	exportData(
		type: string,
		dataVersion: Dictionary<string>,
		hideGroups?: string[],
		bodyFilters?: Dictionary,
		isAdmin?: boolean,
		isRevision = false,
		isGroupBy = false,
	): BaseResponse<ChartType> {
		let preUrl = "";
		if (isGroupBy) {
			preUrl = "group-by/";
		} else if (isRevision) {
			preUrl = "revision-history/";
		}

		return this.httpService.get(`export/dynamic-data/${preUrl}${type}`, {
			params: {
				...dataVersion,
				...(hideGroups
					? {
							hideGroups,
						}
					: {}),
				...(bodyFilters
					? {
							...bodyFilters,
						}
					: {}),
			},
			responseType: "blob",
		});
	}

	/**
	 * Get Dynamic function results
	 *
	 * @async
	 * @since 1.0.35
	 */
	getDynamicFunction(params: DynamicGraphicsParams): BaseResponse<BandComboGraphicsResponse> {
		return this.httpService.get(
			`dynamic-data/dynamic-function/${params.entityName}/${params.functionName}/${params.column}`,
			{
				params: R.omit(["entityName", "functionName", "column"], params),
			},
		);
	}

	/**
	 * Band combo graphics data custom calculation
	 *
	 * @async
	 * @since 1.0.35
	 */
	postBandComboGraphics(body: DynamicCustomGraphic): BaseResponse<BandComboGraphicsResponse> {
		return this.httpService.post(
			`dynamic-data/dynamic-function/${body.entityName}/interModulationCustom/${body.column}`,
			R.omit(["entityName", "functionName", "column"], body),
		);
	}

	/**
	 * Get Band combo graphics table data
	 *
	 * @async
	 * @since 1.0.35
	 */
	getBandGraphics(params: BandsGraphics): BaseResponse<GraphicsTableResponse> {
		return this.httpService.get(
			`dynamic-data/dynamic-function/${params.entityName}/bandGraphicsPopUp/${params.column}`,
			{
				params: R.omit(["column"], params),
			},
		);
	}

	/**
	 * Fetch group by
	 *
	 * @async
	 * @since 1.0.0
	 */
	getGroupBy(entity: string, groupByFilters?: Dictionary): BaseResponse<any> {
		return this.httpService.get(`/dynamic-data/group-by/${entity}`, {
			params: groupByFilters,
		});
	}

	/**
	 * dynamic visualization
	 *
	 * @async
	 * @since 1.0.2
	 */
	visualization(
		entity: string,
		dataVersion: Dictionary<string>,
		dataVersion2?: Dictionary<string>,
		bodyFilters?: Dictionary,
	): BaseResponse<ChartType> {
		return this.httpService.post(`dynamic-data/visualization/${entity}`, {
			versions: dataVersion,
			versions2: dataVersion2,
			options: bodyFilters,
		});
	}

	/**
	 * save profile
	 *
	 * @async
	 * @since 1.0.35
	 */
	postSaveProfile(body: SaveProfileRequest): BaseResponse<SaveProfileRequest> {
		return this.httpService.post(`profile`, body);
	}

	/**
	 * get profiles
	 *
	 * @async
	 * @since 1.0.35
	 */
	getSavedProfiles(body: GetProfileRequest): BaseResponse<GetProfileRequest> {
		return this.httpService.get(`profile`, {
			params: body,
		});
	}

	/**
	 * delete profile
	 *
	 * @async
	 * @since 1.0.35
	 */
	deleteProfile(id: string): BaseResponse<DeleteProfileRequest> {
		return this.httpService.delete(`profile/${id}`);
	}
}

const DynamicService = new DynamicProvider();

export default DynamicService;
