/**
 * @module Constants/collectors
 * @desc All collectors constant variables
 */

import { createReduxConstant } from "src/helpers";

const prefix = createReduxConstant("COLLECTORS");

/**
 * @module COLLECTORS
 * @desc set list of collector documents
 */
export const SET_COLLECTOR_DOCUMENTS = prefix("SET_COLLECTOR_DOCUMENTS");

/**
 * @module COLLECTORS
 * @desc set loading
 */
export const SET_LOADING = prefix("SET_LOADING");

/**
 * @module COLLECTORS
 * @desc set ts numbers with dedicated version list
 */
export const SET_DATAVERSIONS_LIST = prefix("SET_DATAVERSIONS_LIST");

/**
 * @module COLLECTORS
 * @desc set selected ts number
 */
export const SET_SELECTED_GROUP = prefix("SET_SELECTED_GROUP");

/**
 * @module COLLECTORS
 * @desc set selected ts number
 */
export const SET_SELECTED_DATA_TYPE = prefix("SET_SELECTED_DATA_TYPE");

/**
 * @module COLLECTORS
 * @desc set selected version
 */
export const SET_SELECTED_VERSION = prefix("SET_SELECTED_VERSION");

/**
 * @module COLLECTORS
 * @desc set version list
 */
export const SET_VERSION_LIST = prefix("SET_VERSION_LIST");

/**
 * @module COLLECTORS
 * @desc set data version modal open state
 */
export const SET_DATAVERSION_MODAL_OPEN = prefix("SET_DATAVERSION_MODAL_OPEN");

/**
 * @module COLLECTORS
 * @desc set selected collector
 */
export const SET_SELECTED_DOCUMENT = prefix("SET_SELECTED_DOCUMENT");

/**
 * @module COLLECTORS
 * @desc set data version modal open state
 */
export const SAGAS_GET_TABLE_COLLECTORS = prefix("SAGAS_GET_TABLE_COLLECTORS");

/**
 * @module COLLECTORS
 * @desc set selected collector
 */
export const SAGAS_GET_EXTRACTED_TABLE_COLLECTOR = prefix("SAGAS_GET_EXTRACTED_TABLE_COLLECTOR");

/**
 * @module COLLECTORS
 * @desc set data version modal open state
 */
export const SET_TABLE_COLLECTORS = prefix("SET_TABLE_COLLECTORS");

/**
 * @module COLLECTORS
 * @desc set selected collector
 */
export const SET_SELECTED_TABLE_COLLECTOR = prefix("SET_SELECTED_TABLE_COLLECTOR");

/**
 * @module COLLECTORS
 * @desc set selected collector
 */
export const SET_EXTRACTED_TABLE_COLLECTOR = prefix("SET_EXTRACTED_TABLE_COLLECTOR");
