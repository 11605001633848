import React from "react";

export function useOnlineStatus() {
	const [onlineStatus, setOnlineStatus] = React.useState(navigator.onLine ?? true);

	const goOnline = () => setOnlineStatus(true);

	const goOffline = () => setOnlineStatus(false);

	React.useEffect(() => {
		window.addEventListener("online", goOnline);
		window.addEventListener("offline", goOffline);

		return () => {
			window.removeEventListener("online", goOnline);
			window.removeEventListener("offline", goOffline);
		};
	}, []);

	return onlineStatus;
}
