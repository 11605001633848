import "core-js/stable";
import "regenerator-runtime/runtime";

import ReactDOM from "react-dom";
// Libraries Components
import { BrowserRouter } from "react-router-dom";
// Local components
import App from "./App";
// Common components
import { ErrorBoundary } from "src/components/ErrorBoundary";
// Redux
import { appStore, persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// Libraries
import "pwacompat/pwacompat.min";
import * as Sentry from "@sentry/react";
// Utilities
import packageJson from "../package.json";
import { isProduction } from "src/helpers";
import { ApplicationConfig } from "src/app.config";
// locales
import /* webpackChunkName: "Locales" */ "./locales";
// Styles
import "intro.js/introjs.css";
import "antd/dist/reset.css";

const rootElement = document.getElementById("root");
window.addEventListener("DOMContentLoaded", () => {
	const AppComponent = (
		<BrowserRouter>
			<App />
		</BrowserRouter>
	);
	const AppCompact = (
		<ErrorBoundary>
			<Provider store={appStore}>
				{ApplicationConfig.persist.enabled ? (
					<PersistGate loading={null} persistor={persistor}>
						{AppComponent}
					</PersistGate>
				) : (
					AppComponent
				)}
			</Provider>
		</ErrorBoundary>
	);

	/**
	 * Should be completed in the future
	 *
	 * @example
	 *
	 * if (rootElement?.hasChildNodes()) {
	 *    ReactDOM.hydrate(AppCompact, rootElement);
	 * } else {
	 *    ReactDOM.render(AppCompact, rootElement);
	 * }
	 */
	ReactDOM.render(AppCompact, rootElement);
});

if (isProduction()) {
	if (import.meta.env.VITE_APP_SENTRY_DSN) {
		Sentry.init({
			sampleRate: 1,
			tracesSampleRate: 1,
			release: packageJson.version,
			dsn: import.meta.env.VITE_APP_SENTRY_DSN,
			environment: import.meta.env.VITE_APP_ENV,
		});
	}
}
