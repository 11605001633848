/**
 * @module Actions/documents
 * @desc All documents actions
 */
// Types
import type { PDFDocumentProxy, PDFLoadingTask, PDFTreeNode } from "pdfjs-dist/webpack";
import type { Action, Dictionary } from "src/types/common";
import type {
	DocumentCurrentOptionsData,
	Document,
	ReadDocumentData,
	EventBusType,
	GroupWithDedicatedDataTypes,
} from "src/types/documents";
// Constants
import * as types from "./documents.constants";
import { LoadingModel } from "src/types/dynamic";

class DocumentsActionsModel {
	/**
	 * get list of published doucments
	 * @return {Action}
	 */
	public getPublishedDocuments(): Action {
		return {
			type: types.SAGAS_GET_PUBLISHED_DOCUMENTS,
		};
	}

	/**
	 * set list of published doucments
	 * @return {Action<Document[]>}
	 */
	public setPublishedDocuments(data: Document[]): Action<Document[]> {
		return {
			type: types.SET_PUBLISHED_DOCUMENTS,
			payload: data,
		};
	}

	/**
	 * set loading
	 * @param {LoadingModel} data
	 * @return {Action<LoadingModel>}
	 */
	public setLoading(data: LoadingModel): Action<LoadingModel> {
		return {
			type: types.SET_LOADING,
			payload: data,
		};
	}

	/**
	 * set ts numbers with dedicated version list
	 * @param {GroupWithDedicatedDataTypes[]} data
	 * @return {Action<GroupWithDedicatedDataTypes[]>}
	 */
	public setDataVersionsList(data: GroupWithDedicatedDataTypes[]): Action<GroupWithDedicatedDataTypes[]> {
		return {
			type: types.SET_DATAVERSIONS_LIST,
			payload: data,
		};
	}

	/**
	 * set selected ts number
	 * @param {string} data
	 * @return {Action<string>}
	 */
	public setSelectedGroup(data: string): Action<string> {
		return {
			type: types.SET_SELECTED_GROUP,
			payload: data,
		};
	}

	/**
	 * set selected ts number
	 * @param {string} data
	 * @return {Action<string>}
	 */
	public setSelectedDataType(data: string): Action<string> {
		return {
			type: types.SET_SELECTED_DATA_TYPE,
			payload: data,
		};
	}

	/**
	 * set selected version
	 * @param {string} data
	 * @return {Action<string>}
	 */
	public setSelectedVersion(data: string): Action<string> {
		return {
			type: types.SET_SELECTED_VERSION,
			payload: data,
		};
	}

	/**
	 * set ts number list
	 * @param {string[]} data
	 * @return {Action<string[]>}
	 */
	public setTsNumberList(data: string[]): Action<string[]> {
		return {
			type: types.SET_TSNUMBER_LIST,
			payload: data,
		};
	}

	/**
	 * set version list
	 * @param {string[]} data
	 * @return {Action<string[]>}
	 */
	public setVersionList(data: string[]): Action<string[]> {
		return {
			type: types.SET_VERSION_LIST,
			payload: data,
		};
	}

	/**
	 * set data version modal open state
	 * @param {boolean} data
	 * @return {Action<boolean>}
	 */
	public setDataVersionModalOpened(data: boolean): Action<boolean> {
		return {
			type: types.SET_DATAVERSION_MODAL_OPEN,
			payload: data,
		};
	}

	/**
	 * set ts number to documentType mappings
	 * @param {Dictionary<string>} data
	 * @return {Action<Dictionary<string>>}
	 */
	public setTsNumberToDocumentTypeMapping(data: Dictionary<string>): Action<Dictionary<string>> {
		return {
			type: types.SET_TSNUMBER_TO_DOCUMENT_TYPE_MAPPING,
			payload: data,
		};
	}

	/**
	 * set selected document
	 * @param {Partial<Document>} data
	 * @return {Action<Partial<Document>>}
	 */
	public setSelectedDocument(data: Partial<Document>): Action<Partial<Document>> {
		return {
			type: types.SET_SELECTED_DOCUMENT,
			payload: data,
		};
	}

	/**
	 * read pdf document
	 * @param {ReadDocumentData} data
	 * @return {Action<ReadDocumentData>}
	 */
	public readDocument(
		pdfViewer: any,
		loadingTask: PDFLoadingTask<PDFDocumentProxy>,
		pdfLinkService: any,
		eventBus: EventBusType["eventBus"],
	): Action<ReadDocumentData> {
		return {
			type: types.SAGAS_READ_DOCUMENT,
			payload: { pdfViewer, loadingTask, pdfLinkService, eventBus },
		};
	}

	/**
	 * set document current options - including dataversion, scale, page, search
	 * @return {Action<DocumentCurrentOptionsData>}
	 */
	public setCurrentOption(data: DocumentCurrentOptionsData): Action<DocumentCurrentOptionsData> {
		return {
			type: types.SET_CURRENT_OPTION,
			payload: data,
		};
	}

	/**
	 * resetting the documentCurrentOptions to it's default
	 * @return {Action}
	 */
	public resetCurrentOptions(): Action {
		return {
			type: types.RESET_CURRENT_OPTIONS,
		};
	}

	/**
	 * setting the outline
	 * @param {PDFTreeNode[]} data
	 * @return {Action<PDFTreeNode[]>}
	 */
	public setOutline(data: PDFTreeNode[]): Action<PDFTreeNode[]> {
		return {
			type: types.SET_OUTLINE,
			payload: data,
		};
	}

	/**
	 * reset current pdf functions
	 * @return {Action}
	 */
	public resetCurrentPdfFunctions(): Action {
		return {
			type: types.SAGAS_RESET_CURRENT_PDF_FUNCTIONS,
		};
	}

	/**
	 * handle toc open
	 * @return {boolean} open
	 * @return {Action<boolean>}
	 */
	public handleTocOpen(open: boolean): Action<boolean> {
		return {
			type: types.HANDLE_TOC_OPEN,
			payload: open,
		};
	}

	/**
	 * handle toc locked
	 * @return {boolean} lock
	 * @return {Action<boolean>}
	 */
	public handleTocLock(lock: boolean): Action<boolean> {
		return {
			type: types.HANDLE_TOC_LOCKED,
			payload: lock,
		};
	}
}

const DocumentsActions = new DocumentsActionsModel();

export default DocumentsActions;
