/**
 * @module Reducer/Collectors
 * @desc All Documents reducers
 */
import * as React from "react";
// Utilities
import * as R from "ramda";
// Types
import type { Action } from "src/types/common";
import type { TableCollector, CollectorsLoading, GroupWithDedicatedDataTypes } from "src/types/collectors";
// Constants
import * as types from "./collectors.constants";

export interface CollectorsState {
	/** document page loading */
	loading: CollectorsLoading;
	/** whole published documents that came from api */
	collectorDocuments: TableCollector[];
	/** list of ts numbers and their dedicated versions */
	dataVersionDataList: GroupWithDedicatedDataTypes[];
	/** selected ts number item */
	selectedGroup: string;
	/** selected ts number item */
	selectedDataType: string;
	/** selected version item */
	selectedVersion: string;
	/** list of versions to be shown in dropdown */
	versionList: string[];
	/** open state of dataversion modal */
	dataVersionModalOpened: boolean;
	/** document id - to requst from api */
	selectedDocument: Partial<TableCollector>;
	tableCollectors: any[];
	selectedTableCollector: string | null;
	extractedTableCollector: any;
}

const initialState: CollectorsState = {
	loading: {
		dataVersion: false,
		documents: false,
		tableCollectors: false,
		extractedTableCollector: false,
	},
	collectorDocuments: [],
	dataVersionDataList: [],
	selectedGroup: "",
	selectedDataType: "",
	selectedVersion: "",
	versionList: [],
	dataVersionModalOpened: false,
	selectedDocument: {},
	tableCollectors: [],
	selectedTableCollector: null,
	extractedTableCollector: null,
};

const reducer: React.Reducer<CollectorsState, Action> = (state = initialState, action) => {
	const update = R.mergeRight<CollectorsState>(state);
	switch (action.type) {
		case types.SET_LOADING:
			return update({
				loading: {
					...state.loading,
					[action.payload.type]: action.payload.status,
				},
			});
		case types.SET_COLLECTOR_DOCUMENTS:
			return update({
				collectorDocuments: action.payload,
			});
		case types.SET_TABLE_COLLECTORS:
			return update({
				tableCollectors: action.payload,
			});
		case types.SET_SELECTED_TABLE_COLLECTOR:
			return update({
				selectedTableCollector: action.payload,
			});
		case types.SET_EXTRACTED_TABLE_COLLECTOR:
			return update({
				extractedTableCollector: action.payload,
			});
		case types.SET_DATAVERSIONS_LIST:
			return update({
				dataVersionDataList: action.payload,
			});
		case types.SET_SELECTED_GROUP:
			return update({
				selectedGroup: action.payload,
			});
		case types.SET_SELECTED_DATA_TYPE:
			return update({
				selectedDataType: action.payload,
			});
		case types.SET_SELECTED_VERSION:
			return update({
				selectedVersion: action.payload,
			});
		case types.SET_VERSION_LIST:
			return update({
				versionList: action.payload,
			});
		case types.SET_DATAVERSION_MODAL_OPEN:
			return update({
				dataVersionModalOpened: action.payload,
			});
		case types.SET_SELECTED_DOCUMENT:
			return update({
				selectedDocument: action.payload,
			});
		default:
			return state;
	}
};

export default reducer;
